import React, { useEffect, useState } from "react";
import classes from "./../speakerXInstitution.module.scss";
import { Button, Card, FormLoader, Modal } from "../../../Utils";
import {
  Avatar,
  DeleteShower as RejectedShower,
  DropDown,
  UserRow,
} from "../../../Components";

import axiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchSpeakerAcList } from "../../../Store/Actions/SpeakerAction";
const SpeakerCard = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { docId, suspensionStatus, selectedFilter } = props;

  const onRequestActionHandler = async (action, speakerType) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post("/institution/requests", {
        user_uid: docId,
        action,
        speakerType,
      });
      if (res?.data?.data?.success) {
        toast.info(`Request ${action}.`);
      }
      dispatch(fetchSpeakerAcList(false, selectedFilter));
    } catch (err) {
      console.log(err, err.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={`flex ${classes.card} ${
        suspensionStatus && classes.card__suspended
      }`}
    >
      <UserRow
        {...props}
        varient="LIST"
        style={{ maxHeight: "none", overflow: "unset" }}
      />
      <div className={classes.card__actions}>
        {["pending", "student", null, "alumni", "faculty"].includes(
          props.selectedFilter
        ) && (
          <ApprovalConfirmation
            onRequestActionHandler={onRequestActionHandler}
            loading={loading}
            selectedFilter={props.selectedFilter}
          />
        )}
      </div>
    </Card>
  );
};

const ApprovalConfirmation = ({
  loading,
  onRequestActionHandler,
  selectedFilter,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [speakerType, setSpeakerType] = useState("student");
  const modalToggler = () => setShowModal((_p) => !_p);

  const onConfirm = async () => {
    try {
      await onRequestActionHandler("approved", speakerType);
      modalToggler();
    } catch (er) {
      console.log(er);
    }
  };
  const onReject = async () => {
    try {
      await onRequestActionHandler("declined", null);
      modalToggler();
    } catch (er) {
      console.log(er);
    }
  };
  return (
    <div className={classes.approvalConfirmationWrapper}>
      <Button varient="outline" onClick={modalToggler}>
        Request Action
      </Button>
      <Modal
        modalStyle={{ overflow: "unset" }}
        heading="User Speaker Request"
        show={showModal}
        onClose={modalToggler}
        footer={
          <div className={classes.foorterBtn}>
            <div>
              <Button onClick={onConfirm} disabled={loading}>
                Confirm
              </Button>
              <Button
                varient="outline"
                onClick={modalToggler}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
            <RejectedShower
              onClick={onReject}
              varient="rejected"
              title={`Are you sure to ${
                selectedFilter === "pending" ? "Reject" : "Remove"
              }`}
              renderBtn={(p) => (
                <Button
                  type="rejected"
                  varient="outline"
                  {...p}
                  disabled={loading}
                >
                  {selectedFilter === "pending" ? "Reject" : "Remove"}
                </Button>
              )}
            />
          </div>
        }
      >
        <div className={classes.approvalConfirmationWrapper__form}>
          {loading && <FormLoader />}
          <label>
            Select Speaker Type
            <select
              name="speakerType"
              value={speakerType}
              onChange={({ target: { value } }) => setSpeakerType(value)}
            >
              <option value="student">Student</option>
              <option value="faculty">Faculty</option>
              <option value="alumni">Alumni</option>
            </select>
          </label>
        </div>
      </Modal>
    </div>
  );
};

export default SpeakerCard;
