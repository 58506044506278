import React, { useEffect, useState } from "react";
import classes from "./editprofile.module.scss";
import axiosInstance from "./../../axiosInstance";
import { useAuthContext } from "./../../Context/AuthContext";
import { UpdateUsername, IconWrapper, UploadAvatar } from "./../../Components";
import {
  Button,
  Spinner,
  AddonInputField as InputField,
  AddonSelectorField,
} from "../../Utils";
import { ReactComponent as BackArrow } from "./../../Assets/arrow-left.svg";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import convertDateToSpecificFormat from "../../Helper/convertDateToSpecificFormat";

const profileTypes = ["image/png", "image/jpeg"];

// #EDIT PROFILE COMPONENT
const EditProfile = (props) => {
  const {
    authState: { userInfo },
    getUserInfo,
  } = useAuthContext();

  const [username, setUsername] = useState(userInfo.userName || "");
  const [loading, setLoading] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState({
    code: null,
    msg: "",
  });
  const [formFields, setFormFields] = useState({
    institutionName: "",
    location: "",
    foundedYear: new Date(),
    state: "",
    instType: "",
  });

  useEffect(() => {
    const {
      institutionUsername,
      institutionName,
      foundedYear,
      location,
      state,
      instType,
    } = userInfo;
    setUsername(institutionUsername);
    setFormFields({
      institutionName: institutionName || "",
      location: location || "",

      foundedYear: new Date(`1/1/${foundedYear}`),
      state,
      instType,
    });
  }, []);
  const convertDateIntoYear = (date) => {
    return convertDateToSpecificFormat(date, "year") + 1;
  };

  const profileUploadHandler = async (e) => {
    let selected = e.target.files[0];
    console.log(selected, "47");
    if (selected && profileTypes.includes(selected.type)) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("file", selected);
        formData.append("Content-Type", "application/json");
        let API_URL = `/institution/reuploadProfile/${userInfo.docId}`;
        if (userInfo.imageFilename) {
          API_URL += `/${userInfo.imageFilename}`;
        }
        const res = await axiosInstance.post(API_URL, formData);
        const { data } = res.data;
        await getUserInfo();
      } catch (err) {
        console.log(err.response);
      } finally {
        setLoading(false);
      }
    }
  };
  const onUsernameChange = (e) => {
    setUsername(e.target.value?.toLowerCase());
  };
  const onUpdateUsernameHandler = async () => {
    try {
      const res = await axiosInstance.post("/institution/updateUsername", {
        userName: username,
      });
      await getUserInfo();
    } catch (err) {}
  };

  const onFormChangeHandler = (e) => {
    const { value, name } = e.target;
    setFormFields((p) => ({ ...p, [name]: value }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const res = await axiosInstance.put("/institution/edit", {
        ...formFields,
        foundedYear: parseInt(convertDateIntoYear(formFields.foundedYear)),
      });

      toast.success("Changes Saved Successfully");
      await getUserInfo();
    } catch (err) {
      setNotifyMsg({
        code: "red",
        msg: err?.response?.data?.error?.message || "There is some error",
      });
    } finally {
      setLoading(false);
    }
  };
  console.log(props);
  return (
    <div className={classes.container}>
      <IconWrapper
        icon={BackArrow}
        className={classes.container__backArrow}
        onClick={() => props.history.push("/")}
      />
      <h1>Edit Profile</h1>
      <div className={classes["space-y-6"]}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <UploadAvatar
            src={`${userInfo.profilePicture}&cache=${new Date().getTime()}`}
            loading={loading}
            onImageInputChange={profileUploadHandler}
          />
        </div>
        <UpdateUsername
          uname={userInfo.institutionUsername}
          userName={username}
          onChange={onUsernameChange}
          updateHandler={onUpdateUsernameHandler}
          requestUrl="/institution/checkUsernameIsValid"
        />

        <form className={classes["space-y-6"]} onSubmit={submitHandler}>
          <InputField
            name="institutionName"
            type="text"
            placeholder="Institution Name"
            required
            value={formFields["institutionName"]}
            onChange={onFormChangeHandler}
          />
          <InputField
            name="location"
            type="text"
            placeholder="Location"
            required
            value={formFields["location"]}
            onChange={onFormChangeHandler}
          />
          <InputField
            name="state"
            type="text"
            placeholder="State"
            required
            value={formFields["state"]}
            onChange={onFormChangeHandler}
          />
          <AddonSelectorField
            name="instType"
            placeholder="Public/Private"
            required
            value={formFields["instType"]}
            onChange={onFormChangeHandler}
            options={[
              { value: "public", text: "Public" },
              { value: "private", text: "Private" },
            ]}
          />

          <div className={classes.formDateField}>
            <p>Select Founded year</p>
            <DatePicker
              name="foundedYear"
              className={classes.formDateField__datePicker}
              selected={formFields["foundedYear"]}
              onChange={(date) =>
                onFormChangeHandler({
                  target: { value: date, name: "foundedYear" },
                })
              }
              showYearPicker
              dateFormat="yyyy"
              popperPlacement="top-start"
              maxDate={new Date()}
            />
          </div>
          {loading ? (
            <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
          ) : (
            <Button type="submit" className={classes["submitBtn"]}>
              Save Changes
            </Button>
          )}
          {notifyMsg.code && (
            <h3 style={{ color: notifyMsg.code || "inherit" }}>
              {notifyMsg.msg}
            </h3>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
