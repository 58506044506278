import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./opinion.module.scss";
import axiosInstance from "./../../axiosInstance";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";
import { format } from "date-fns";
import OpinionListComp from "./List";
import CreateOpinionComp from "./Create";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { toast } from "react-toastify";

import {
  fetchOpinionList,
  fetchMoreOpinionsList,
  setOpinionFilter,
  setOpinionLoadingFilter,
} from "./../../Store/Actions/OpinionAction";

import { RefreshListButton } from "../../Components";

import { AddonSelectorField } from "../../Utils";
import DatePicker from "react-datepicker";
import convertDateToSpecificFormat from "../../Helper/convertDateToSpecificFormat";
import rangeFunction from "../../Helper/rangeFunction";

const opinionSchema = yup.object().shape({
  plantiff: yup.string().required("Plantiff Name is Required"),
  defendant: yup.string().required("Defendant Name is Required"),
  scoutsOpinion: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Opinion Link is Required"),
  docketNumber: yup.number().required("Docket Number is Required"),
  caseNo: yup.number().required("Case Number is Required"),
  dateOfPublish: yup.date().required("Publish date is Required"),
  termYear: yup.date().required("Term Year is Required"),
});

const OpinonsView = (props) => {
  const convertDateIntoYear = (date) => {
    return convertDateToSpecificFormat(date, "year") + 1;
  };
  const dispatch = useDispatch();
  const {
    isEnd,
    opinionList,
    isEmpty,
    loading,
    filter,
    filterLoading,
  } = useSelector(({ opinionReducer }) => opinionReducer);

  const [showFormModal, setShowFormModal] = useState(false);
  const { handleSubmit, register, setValue, errors, control } = useForm({
    defaultValues: {
      plantiff: "",
      defendant: "",
      scoutsOpinion: "",
      docketNumber: "",
      caseNo: "",
      dateOfPublish: format(new Date(), "yyyy-MM-dd"),
      termYear: new Date(),
    },
    mode: "onChange",
    resolver: yupResolver(opinionSchema),
  });
  const [formLoader, setFormLoader] = useState(false);

  const [filterDate, setFilterDate] = useState("ALL");

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      if (opinionList.length === 0) {
        dispatch(fetchOpinionList());
      }
      isFirstRender.current = false;
    } else {
      dispatch(fetchOpinionList());
    }
  }, []);

  const moreBtnHandler = () => dispatch(fetchMoreOpinionsList());
  // On Scroll reach to bottom fetch more opinions
  useScrollReachToBottom(moreBtnHandler);

  const formModalHandler = () => setShowFormModal((_p) => !_p);
  const opinionSubmitHandler = async (formData) => {
    try {
      setFormLoader(true);
      const res = await axiosInstance.post("/admin/createOpinion", {
        ...formData,
        dateOfPublish: formData.dateOfPublish.toISOString(),
        // termYear: convertDateIntoYear(formData.termYear),
        termYear: parseInt(formData.termYear.getFullYear()),
      });
      formModalHandler();
      toast.success("Opinion created Succussfully.");
      dispatch(fetchOpinionList());
    } catch (err) {
      console.log(err, err.response);
    } finally {
      setFormLoader(false);
    }
  };

  const onChangeYearFilter = (year) => {
    // setFilterDate(date);
    // const year = convertDateIntoYear(date);
    // dispatch(setOpinionFilter(!date ? "ALL" : year));
    // dispatch(setOpinionLoadingFilter(true));

    setFilterDate(year);
    dispatch(setOpinionFilter(year === "ALL" ? year : parseInt(year)));
    dispatch(setOpinionLoadingFilter(true));

    dispatch(fetchOpinionList());
  };

  const onChangeTermYearFieldCreateForm = (date) => {
    setValue("termYear", convertDateIntoYear(date));
  };

  const isShowMoreBtn = opinionList.length > 0;
  return (
    <div className={classes.profileWrapper}>
      <h1 className="text-center">OPINIONS</h1>
      <RefreshListButton onClick={() => dispatch(fetchOpinionList())} />

      <div className={classes.filterWrapper}>
        <AddonSelectorField
          width="220px"
          placeholder="Filter By Term Year"
          value={filterDate}
          onChange={({ target: { value } }) => onChangeYearFilter(value)}
          options={[
            { value: "ALL", text: "All" },
            ...rangeFunction(2014, 2020, 1)
              .map((no) => ({
                value: no,
                text: no,
              }))
              .reverse(),
          ]}
        />

        {/* <DatePicker
          className={classes.filterWrapper__datePicker}
          selected={filterDate}
          onChange={(date) => onChangeYearFilter(date)}
          showYearPicker
          dateFormat="yyyy"
          isClearable
          placeholderText="filter by term year"
          minDate={new Date("1/1/2013")}
          maxDate={new Date()}
        /> */}
      </div>

      <CreateOpinionComp
        handleSubmit={handleSubmit}
        register={register}
        showFormModal={showFormModal}
        formModalHandler={formModalHandler}
        opinionSubmitHandler={opinionSubmitHandler}
        errors={errors}
        formLoader={formLoader}
        onChangeTermYearFieldCreateForm={onChangeTermYearFieldCreateForm}
        control={control}
      />
      <OpinionListComp
        opinionList={opinionList}
        isShowMoreBtn={isShowMoreBtn}
        isEnd={isEnd}
        isEmpty={isEmpty}
        loading={loading}
        moreBtnHandler={moreBtnHandler}
        filterLoading={filterLoading}
      />
    </div>
  );
};

export default OpinonsView;
