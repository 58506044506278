import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./speakerXInstitution.module.scss";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";
import ListComp from "./List";

import { toast } from "react-toastify";
import { fetchSpeakerAcList } from "./../../Store/Actions/SpeakerAction";
import { fetchBadgeList } from "./../../Store/Actions/BadgeAction";
import { Button } from "../../Utils";

const InstitutionView = (props) => {
  const dispatch = useDispatch();
  const speakerReducer = useSelector(({ speakerReducer }) => speakerReducer);
  const { isEnd, list, isEmpty, loading } = speakerReducer;
  const [selectedFilter, setSelectedFilter] = useState("pending");
  useEffect(() => {
    dispatch(fetchSpeakerAcList(false, selectedFilter));
  }, [selectedFilter]);

  const moreBtnHandler = () =>
    !isEnd && dispatch(fetchSpeakerAcList(true, selectedFilter));
  // On Scroll reach to bottom fetch more
  // useScrollReachToBottom(moreBtnHandler);

  const onChangeFilter = (v) => {
    setSelectedFilter(v);
  };
  return (
    <div className={classes.profileWrapper}>
      <h1 className="text-center">SPEAKERS</h1>
      <div className={classes.filterSpeakerWrapper}>
        <Button
          onClick={() => onChangeFilter(null)}
          varient={selectedFilter === null ? "none" : "outline"}
        >
          All
        </Button>
        <Button
          onClick={() => onChangeFilter("faculty")}
          varient={selectedFilter === "faculty" ? "none" : "outline"}
        >
          Faculty
        </Button>
        <Button
          onClick={() => onChangeFilter("student")}
          varient={selectedFilter === "student" ? "none" : "outline"}
        >
          Student
        </Button>
        <Button
          onClick={() => onChangeFilter("alumni")}
          varient={selectedFilter === "alumni" ? "none" : "outline"}
        >
          Alumni
        </Button>
        <Button
          onClick={() => onChangeFilter("pending")}
          varient={selectedFilter === "pending" ? "none" : "outline"}
        >
          Requests
        </Button>
      </div>

      <ListComp
        list={list}
        isShowMoreBtn={!isEnd}
        isEnd={isEnd}
        loading={loading}
        moreBtnHandler={moreBtnHandler}
        isEmpty={isEmpty}
        selectedFilter={selectedFilter}
      />
    </div>
  );
};

export default InstitutionView;
