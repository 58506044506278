import React from "react";
import classes from "./../components.module.scss";
import Avatar from "../Avatar";
import convertDateToSpecificFormat from "./../../Helper/convertDateToSpecificFormat";

const InstitutionRow = (props) => {
  return (
    <div className={classes.institutionRowWrapper}>
      <div className={classes["location-wrapper"]}>
        <Avatar
          src={props.profilePicture}
          size={52}
          varient={props.badge ? "badge" : "none"}
          badge={props.badge}
        />
        <p>{props.location}</p>
      </div>
      <div className={classes["info"]}>
        <p>{props.institutionName}</p>
        <p>@{props.institutionUsername}</p>
        {/* <p>{convertDateToSpecificFormat(props.founded)}</p> */}
        <p>{props.foundedYear}</p>
      </div>
    </div>
  );
};

export default InstitutionRow;
