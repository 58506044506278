import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "./../axiosInstance";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const data = MakeProvider();
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

const MakeProvider = () => {
  const history = useHistory();

  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    expiresAt: null,
    userInfo: null,
    role: null,
  });

  const setAuthInfo = ({ isAuthenticated, userInfo, expiresAt, role }) => {
    setAuthState({
      isAuthenticated,
      userInfo,
      expiresAt,
      role,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance.get("/user/checkAuthStatus");
        console.log(res, "checkAuthStatus");
        const { data } = res.data;
        setAuthState({
          isAuthenticated: true,
          expiresAt: data.exp,
          userInfo: data,
          role: data.role,
        });
      } catch (err) {
        setAuthState({
          isAuthenticated: false,
          expiresAt: null,
          userInfo: {},
          role: "public",
        });
      }
    })();
  }, []);

  const logout = async () => {
    try {
      await axiosInstance.get("/user/logoutUser");
      // if (!history.location.pathname.startsWith("/ss/")) {
      //   history.push("/");
      // }
      setAuthState({
        isAuthenticated: false,
        expiresAt: null,
        userInfo: {},
        role: "public",
      });
      history.push("/");
    } catch (err) {
      console.log(err);
      console.log(err?.response);
    }
  };
  const login = ({ email, password }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axiosInstance.post("/user/loginUser", {
          email,
          password,
          loginRole: "admin",
        });
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          resolve(false);
        }
      } catch (err) {
        reject(err);
      }
    });
  };
  const signup = ({ email, password, firstName, lastName, userName }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axiosInstance.post("/user/signupUser", {
          email,
          password,
          firstName,
          lastName,
          userName,
        });
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          resolve(false);
        }
      } catch (err) {
        reject(err);
      }
    });
  };
  const loginWithGoogle = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axiosInstance.post("/user/googleLogin", {
          token,
        });
        const { data } = res.data;
        if (data) {
          resolve(data);
        }
      } catch (err) {
        reject(err);
      }
    });
  };
  const loginWithTwitter = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axiosInstance.get("/twitter");
        const data = res.data;
        if (data) {
          resolve(data);
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  const getUserInfo = async () => {
    try {
      const res = await axiosInstance.get("/user/checkAuthStatus");
      const { data } = res.data;
      setAuthState({
        isAuthenticated: true,
        expiresAt: data.exp,
        userInfo: data,
        role: data.role,
      });
      console.log(data);
    } catch (err) {
      console.log(err.response);
    }
  };

  return {
    authState,
    setAuthState: (authInfo) => setAuthInfo(authInfo),
    getUserInfo,
    logout,
    login,
    signup,
    loginWithGoogle,
    loginWithTwitter,
  };
};

export const useAuthContext = () => useContext(AuthContext);
export { AuthProvider };

// class App extends Component {
//   onSuccess = (param) => {
//     console.log("yahoo", param.getAuthResponse().id_token);
//   };
//   onFailure = () => {
//     console.log("failure");
//   };
//   componentDidMount() {
//     window.gapi.load("auth2", () => {
//       window.gapi.auth2
//         .init({
//           client_id:
//             "217211939482-269kuft8s39d7vsis8hp2jm812ndig1d.apps.googleusercontent.com",
//           cookiepolicy: "single_host_origin",
//           scope: "profile email",
//         })
//         .then(() => {
//           const element = document.getElementById("google-auth-btn");
//           const instance = window.gapi.auth2.getAuthInstance();
//           instance.attachClickHandler(
//             element,
//             {},
//             this.onSuccess,
//             this.onFailure
//           );
//         })
//         .catch((err) => {
//           console.log("error occured", err);
//         });
//     });
//   }
//   render() {
//     return (
//       <>
//         <div id="google-bt
