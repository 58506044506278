import axiosInstance from "./../../axiosInstance";

export const FETCH_USER_LIST = "FETCH_USER_LIST";

export const USER_LOADING = "USER_LOADING";
export const USER_IS_END = "USER_IS_END";
export const USER_IS_EMPTY = "USER_IS_EMPTY";

export const SET_USER_DOC_PROPERTY = "SET_USER_DOC_PROPERTY";
export const ADD_USER_IN_LIST = "ADD_USER_IN_LIST";

export const setUserLoading = (val) => {
  return {
    type: USER_LOADING,
    payload: {
      loading: val,
    },
  };
};
export const setUserIsEnd = (val) => {
  return {
    type: USER_IS_END,
    payload: {
      isEnd: val,
    },
  };
};
export const setUserIsEmpty = (val) => {
  return {
    type: USER_IS_EMPTY,
    payload: {
      isEmpty: val,
    },
  };
};
export const setUserList = (val) => {
  return {
    type: FETCH_USER_LIST,
    payload: {
      userList: val,
    },
  };
};

export const fetchUserList = (fetchMore) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().userReducer;
      dispatch(setUserLoading(true));
      let url = "/admin/listUsers";
      const lastIndex = previousReducerState.userList.length - 1;
      if (fetchMore && lastIndex >= 0) {
        url += `/${previousReducerState.userList[lastIndex]?.docId}`;
      }
      const res = await axiosInstance.post(url, { role: "users" });
      const { data, isEnd, isEmpty } = res.data;
      if (fetchMore) {
        dispatch(setUserList([...previousReducerState.userList, ...data]));
      } else {
        dispatch(setUserList([...data]));
        dispatch(setUserIsEmpty(isEmpty));
      }
      dispatch(setUserIsEnd(isEnd));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setUserLoading(false));
    }
  };
};

export const setDocProperty = (docId, name, val) => {
  return {
    type: SET_USER_DOC_PROPERTY,
    payload: {
      name,
      val,
      docId,
    },
  };
};
export const updateSingleUser = (docId) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().userReducer;
      let url = `/user/info-by-id/${docId}/user`;

      const res = await axiosInstance.get(url);
      const { data } = res.data;

      const clonedList = [...previousReducerState.userList];
      const getIndexOfElem = clonedList.findIndex((o) => o.docId === docId);
      clonedList[getIndexOfElem] = {
        ...data,
      };
      dispatch(setUserList([...clonedList]));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
    }
  };
};
export const addUserInList = (val) => {
  return {
    type: ADD_USER_IN_LIST,
    payload: {
      user: val,
    },
  };
};
