import React from "react";
import { Button, Modal } from "../../Utils";
import classes from "./badges.module.scss";
import IconWrapper from "./../../Components/IconWrapper";
import { ReactComponent as PlusIcon } from "./../../Assets/plus.svg";
import FormModal from "./FormModal";
import FormLoader from "./../../Utils/FormLoader";

const CreateOpinion = ({
  showFormModal,
  formModalHandler,
  register,
  handleSubmit,
  errors,
  submitHandler,
  formLoader,
  watch,
}) => {
  return (
    <div className="flex flex-center">
      <IconWrapper icon={PlusIcon} onClick={formModalHandler} />
      <Modal
        show={showFormModal}
        onClose={formModalHandler}
        heading="Create Badge"
      >
        {formLoader && <FormLoader />}
        <FormModal
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          submitHandler={submitHandler}
          formLoader={formLoader}
          formModalHandler={formModalHandler}
          watch={watch}
        />
      </Modal>
    </div>
  );
};

export default CreateOpinion;
