import React from "react";

import { ReactComponent as TwitterIcon } from "./assets/twitter.svg";
import { ReactComponent as InstagramIcon } from "./assets/instagram.svg";
import { ReactComponent as LinkedInIcon } from "./assets/linkedin-in.svg";
import IconWrapper from "./../IconWrapper";

const SocialButtons = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <a href="https://twitter.com/scotusspeaks">
        <IconWrapper styleIcon={{ fill: "#fff" }} icon={TwitterIcon} />
      </a>
      <a href="https://www.instagram.com/scotusspeaks/">
        <IconWrapper styleIcon={{ fill: "#fff" }} icon={InstagramIcon} />
      </a>
      <a href="https://www.instagram.com/scotusspeaks/">
        <IconWrapper styleIcon={{ fill: "#fff" }} icon={LinkedInIcon} />
      </a>
    </div>
  );
};

export default SocialButtons;
