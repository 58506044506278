import React from "react";
import classes from "./button.module.css";

const Button = ({ className, children, varient = "none", ...restProps }) => {
  return (
    <button
      className={`${classes.button} ${className} ${
        varient === "outline" && classes.outline
      } `}
      {...restProps}
    >
      {children}
    </button>
  );
};
export const LinkButton = ({
  className,
  children,
  varient = "none",
  ...restProps
}) => {
  return (
    <a
      className={`${classes.button} ${className} ${
        varient === "outline" && classes.outline
      } `}
      {...restProps}
    >
      {children}
    </a>
  );
};

export default Button;
