import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./useradmin.module.scss";
import axiosInstance from "./../../axiosInstance";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";
import { format } from "date-fns";
import ListComp from "./List";
import CreateComp from "./Create";
import EditDocProfile from "./EditModal/index";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { toast } from "react-toastify";
import { fetchInstitutionList } from "./../../Store/Actions/InstitutionAction";
import { fetchBadgeList } from "./../../Store/Actions/BadgeAction";
import { RefreshListButton } from "./../../Components";
import convertDateToSpecificFormat from "../../Helper/convertDateToSpecificFormat";

// FUNCTIONAL COMPONENT
const institutionSchema = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Email is required."),
  institutionName: yup.string().required("Name is required."),
  location: yup.string().required("Location is required."),
  state: yup.string().required("State is required."),
  instType: yup.string().required("Ins. is Public/Private is required."),
  foundedYear: yup.date().required("Founded Year is Required"),
});

const InstitutionView = (props) => {
  const dispatch = useDispatch();
  const { isEnd, list, isEmpty, loading } = useSelector(
    ({ institutionReducer }) => institutionReducer
  );
  const badgeList = useSelector(({ badgeReducer }) => badgeReducer.badgeList);

  const [showFormModal, setShowFormModal] = useState(false);
  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      email: "",
      institutionName: "",
      location: "",
      foundedYear: new Date(),
    },
    mode: "onChange",
    resolver: yupResolver(institutionSchema),
  });
  const [formLoader, setFormLoader] = useState(false);
  const [username, setUsername] = useState("");
  const [isValidUsername, setIsValidUsername] = useState(false);
  const [formMsg, setFormMsg] = useState(null);
  const [showWhichView, setShowWhichView] = useState("");

  const [editUserModal, setEditUserModal] = useState(null);

  useEffect(() => {
    if (list.length === 0) {
      dispatch(fetchInstitutionList());
    }
    if (badgeList.length === 0) {
      dispatch(fetchBadgeList());
    }
  }, []);

  const moreBtnHandler = () => !isEnd && dispatch(fetchInstitutionList(true));
  // On Scroll reach to bottom fetch more
  // useScrollReachToBottom(moreBtnHandler);

  const onUsernameChange = (e) => {
    setUsername(e.target.value?.toLowerCase());
  };
  const formModalHandler = () => setShowFormModal((_p) => !_p);
  const modalCloseHandler = () => {
    formModalHandler();
    setUsername("");
    setIsValidUsername(false);
    setFormMsg(null);
  };
  const convertDateIntoYear = (date) => {
    return convertDateToSpecificFormat(date, "year") + 1;
  };
  const submitHandler = async (formData) => {
    try {
      if (!isValidUsername) {
        console.log("Please choose valid username.");
        return;
      }
      setFormLoader(true);
      const res = await axiosInstance.post(`/admin/createInstitution`, {
        institutionUsername: username,
        ...formData,
        foundedYear: parseInt(formData.foundedYear.getFullYear()),
      });
      modalCloseHandler();
      toast.success("Institution created Succussfully.");
      dispatch(fetchInstitutionList());
    } catch (err) {
      console.log(err, formData.foundedYear);
      setFormMsg(err.response?.data?.error?.message || null);
    } finally {
      setFormLoader(false);
    }
  };
  const onEditClickHandler = ({ docId }) => {
    setEditUserModal(docId);
    setShowWhichView("EDIT_VIEW");
  };
  const closedEditUserModal = () => {
    setShowWhichView("");
    setEditUserModal(null);
  };
  return (
    <div className={classes.profileWrapper}>
      {showWhichView === "" && (
        <Fragment>
          <h1 className="text-center">INSTITUTIONS</h1>
          <RefreshListButton onClick={() => dispatch(fetchInstitutionList())} />
        </Fragment>
      )}

      {showWhichView === "EDIT_VIEW" && (
        <EditDocProfile
          editDocId={editUserModal}
          closedEditUserModal={closedEditUserModal}
          convertDateIntoYear={convertDateIntoYear}
        />
      )}

      {showWhichView === "" && (
        <CreateComp
          handleSubmit={handleSubmit}
          register={register}
          showFormModal={showFormModal}
          formModalHandler={formModalHandler}
          submitHandler={submitHandler}
          errors={errors}
          formLoader={formLoader}
          username={username}
          onUsernameChange={onUsernameChange}
          setIsValidUsername={setIsValidUsername}
          modalCloseHandler={modalCloseHandler}
          formMsg={formMsg}
          control={control}
        />
      )}
      {showWhichView === "" && (
        <ListComp
          list={list}
          isShowMoreBtn={!isEnd}
          isEnd={isEnd}
          loading={loading}
          moreBtnHandler={moreBtnHandler}
          isEmpty={isEmpty}
          onEditClickHandler={onEditClickHandler}
        />
      )}
    </div>
  );
};

export default InstitutionView;
