import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./badges.module.scss";
import axiosInstance from "./../../axiosInstance";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";
import BadgesListComp from "./List";
import CreateBadgeComp from "./Create";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { toast } from "react-toastify";
import { fetchBadgeList } from "./../../Store/Actions/BadgeAction";
import { RefreshListButton } from "../../Components";

const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
const badgeSchema = yup.object().shape({
  badgeName: yup.string().required("Badge Name is Required"),
  src: yup
    .mixed()
    .required("Badge Image is required")
    // .test(
    //   "FILE_SIZE",
    //   "Uploaded file is too big.",
    //   (value) => !value || (value && value.size <= FILE_SIZE)
    // )
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      return !value || (value && SUPPORTED_FORMATS.includes(value[0].type));
    }),
});

const BadgesView = (props) => {
  const dispatch = useDispatch();
  const badgeReducer = useSelector(({ badgeReducer }) => badgeReducer);
  const { isEnd, badgeList, isEmpty, loading } = badgeReducer;

  const [showFormModal, setShowFormModal] = useState(false);
  const { handleSubmit, register, watch, errors } = useForm({
    defaultValues: {
      badgeName: "",
      src: null,
    },
    mode: "onChange",
    resolver: yupResolver(badgeSchema),
  });
  const [formLoader, setFormLoader] = useState(false);

  useEffect(() => {
    if (badgeList.length === 0) {
      dispatch(fetchBadgeList());
    }
  }, []);

  const moreBtnHandler = () => !isEnd && dispatch(fetchBadgeList(true));
  // On Scroll reach to bottom fetch more
  useScrollReachToBottom(moreBtnHandler);

  const formModalHandler = () => setShowFormModal((_p) => !_p);
  const submitHandler = async (formData) => {
    try {
      setFormLoader(true);
      const { src, badgeName } = formData;
      const formDataRequest = new FormData();
      formDataRequest.append("file", src[0]);
      formDataRequest.append("Content-Type", "application/json");
      const res = await axiosInstance.post(
        `/admin/createBadge/${badgeName}`,
        formDataRequest
      );
      formModalHandler();
      toast.success("Badge created Succussfully.");
      dispatch(fetchBadgeList());
    } catch (err) {
      console.log(err, err.response);
    } finally {
      setFormLoader(false);
    }
  };

  return (
    <div className={classes.profileWrapper}>
      <h1 className="text-center">BADGES</h1>
      <RefreshListButton onClick={() => dispatch(fetchBadgeList())} />
      <CreateBadgeComp
        handleSubmit={handleSubmit}
        register={register}
        showFormModal={showFormModal}
        formModalHandler={formModalHandler}
        submitHandler={submitHandler}
        errors={errors}
        formLoader={formLoader}
        watch={watch}
      />
      <BadgesListComp
        badgeList={badgeList}
        isShowMoreBtn={!isEnd}
        isEnd={isEnd}
        loading={loading}
        moreBtnHandler={moreBtnHandler}
      />
    </div>
  );
};

export default BadgesView;
