import axiosInstance from "./../../axiosInstance";

export const FETCH_INSTITUTION_LIST = "FETCH_INSTITUTION_LIST";

export const INSTITUTION_LOADING = "INSTITUTION_LOADING";
export const INSTITUTION_IS_END = "INSTITUTION_IS_END";
export const INSTITUTION_IS_EMPTY = "INSTITUTION_IS_EMPTY";

export const SET_INSTITUTION_DOC_PROPERTY = "SET_INSTITUTION_DOC_PROPERTY";

export const setInstitutionLoading = (val) => {
  return {
    type: INSTITUTION_LOADING,
    payload: {
      loading: val,
    },
  };
};
export const setInstitutionIsEnd = (val) => {
  return {
    type: INSTITUTION_IS_END,
    payload: {
      isEnd: val,
    },
  };
};
export const setInstitutionIsEmpty = (val) => {
  return {
    type: INSTITUTION_IS_EMPTY,
    payload: {
      isEmpty: val,
    },
  };
};
export const setInstitutionList = (val) => {
  return {
    type: FETCH_INSTITUTION_LIST,
    payload: {
      list: val,
    },
  };
};

export const fetchInstitutionList = (fetchMore) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().institutionReducer;
      dispatch(setInstitutionLoading(true));
      let url = "/admin/listUsers";
      const lastIndex = previousReducerState.list.length - 1;
      if (fetchMore && lastIndex >= 0) {
        url += `/${previousReducerState.list[lastIndex]?.docId}`;
      }
      const res = await axiosInstance.post(url, { role: "institutions" });
      const { data, isEnd, isEmpty } = res.data;
      if (fetchMore) {
        dispatch(setInstitutionList([...previousReducerState.list, ...data]));
      } else {
        dispatch(setInstitutionList([...data]));
        dispatch(setInstitutionIsEmpty(isEmpty));
      }
      dispatch(setInstitutionIsEnd(isEnd));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setInstitutionLoading(false));
    }
  };
};

export const setDocProperty = (docId, name, val) => {
  return {
    type: SET_INSTITUTION_DOC_PROPERTY,
    payload: {
      name,
      val,
      docId,
    },
  };
};
export const updateSingleInstitution = (docId) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().institutionReducer;
      let url = `/user/info-by-id/${docId}/institution`;

      const res = await axiosInstance.get(url);
      const { data } = res.data;

      const clonedList = [...previousReducerState.list];
      const getIndexOfElem = clonedList.findIndex((o) => o.docId === docId);
      clonedList[getIndexOfElem] = {
        ...data,
      };
      dispatch(setInstitutionList([...clonedList]));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
    }
  };
};
