import React, { useState } from "react";

import IconWrapper from "./../IconWrapper";
import { Button } from "./../../Utils";
import { ReactComponent as TrashIcon } from "./../../Assets/trash.svg";
import classes from "./deleteshower.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import Spinner from "../../Utils/Spinner";

const DeleteShower = ({
  onClick = () => null,
  className,
  title = "Are you sure to Delete ?",
  actionIcon = TrashIcon,
  varient = "",
  renderBtn: RenderBtn,
  ...restProps
}) => {
  const [showConfirmationWrapper, setShowConfirmationWrapper] = useState(false);
  const [loading, setLoading] = useState(false);
  const varientAnim = {
    show: {
      scale: 1,
      opacity: 1,
    },
    hide: {
      scale: 0,
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
  };

  const onConfirmClickHandler = async () => {
    try {
      setLoading(true);
      await onClick();
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
      showToggler();
    }
  };

  const showToggler = () => setShowConfirmationWrapper((_p) => !_p);
  const wrapperClassName = `${classes.deleteShowerComp} ${className}`;
  return (
    <div className={wrapperClassName}>
      {varient === "" ? (
        <IconWrapper icon={actionIcon} onClick={showToggler} />
      ) : (
        <RenderBtn onClick={showToggler} />
      )}
      <AnimatePresence>
        {showConfirmationWrapper && (
          <motion.div
            className={classes.confirmationWrapper}
            variants={varientAnim}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <p>{title}</p>
            {loading ? (
              <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
            ) : (
              <div className={classes.actions}>
                <Button onClick={onConfirmClickHandler}>Confirm</Button>
                <Button varient="outline" onClick={showToggler}>
                  Cancel
                </Button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default DeleteShower;
