import React from "react";
import { Button, Modal } from "../../Utils";
import classes from "./opinion.module.scss";
import IconWrapper from "./../../Components/IconWrapper";
import { ReactComponent as PlusIcon } from "./../../Assets/plus.svg";
import FormModal from "./FormModal";
import FormLoader from "./../../Utils/FormLoader";

const CreateOpinion = ({
  showFormModal,
  formModalHandler,
  register,
  handleSubmit,
  errors,
  opinionSubmitHandler,
  formLoader,
  control,
}) => {
  return (
    <div className="flex flex-center">
      <IconWrapper icon={PlusIcon} onClick={formModalHandler} />
      <Modal
        show={showFormModal}
        onClose={formModalHandler}
        heading="Create Opinion"
        modalStyle={{ height: "560px" }}
      >
        {formLoader && <FormLoader />}
        <FormModal
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          opinionSubmitHandler={opinionSubmitHandler}
          formLoader={formLoader}
          formModalHandler={formModalHandler}
          control={control}
        />
      </Modal>
    </div>
  );
};

export default CreateOpinion;
