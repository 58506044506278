import axiosInstance from "./../../axiosInstance";

export const ADD_OPINION = "ADD_OPINION";
export const REMOVE_OPINION = "REMOVE_OPINION";
export const FETCH_OPINION_LIST = "FETCH_OPINION_LIST";

export const OPINION_LOADING = "OPINION_LOADING";
export const OPINION_IS_END = "OPINION_IS_END";
export const OPINION_IS_EMPTY = "OPINION_IS_EMPTY";

export const SET_OPINION_DOC_PROPERTY = "SET_OPINION_DOC_PROPERTY";
export const REMOVE_OPINION_DOC_ARRAY = "REMOVE_OPINION_DOC_ARRAY";
export const CHANGE_OPINION_FILTER = "CHANGE_OPINION_FILTER";
export const OPINION_FILTER_LOADING = "OPINION_FILTER_LOADING";

export const setOpinionLoadingFilter = (val) => {
  return {
    type: OPINION_FILTER_LOADING,
    payload: {
      filterLoading: val,
    },
  };
};
export const setOpinionFilter = (val) => {
  return {
    type: CHANGE_OPINION_FILTER,
    payload: {
      val,
    },
  };
};
export const setOpinionLoading = (val) => {
  return {
    type: OPINION_LOADING,
    payload: {
      loading: val,
    },
  };
};
export const setOpinionIsEnd = (val) => {
  return {
    type: OPINION_IS_END,
    payload: {
      isEnd: val,
    },
  };
};
export const setOpinionIsEmpty = (val) => {
  return {
    type: OPINION_IS_EMPTY,
    payload: {
      isEmpty: val,
    },
  };
};
export const setOpinionList = (val) => {
  return {
    type: FETCH_OPINION_LIST,
    payload: {
      opinionList: val,
    },
  };
};

export const fetchOpinionList = (fetchMore) => {
  return async (dispatch, getState) => {
    try {
      const previousOpinionReducer = getState().opinionReducer;
      dispatch(setOpinionLoading(true));
      let url = `/admin/opinions/${previousOpinionReducer.filter}`;
      const lastIndex = previousOpinionReducer.opinionList.length - 1;
      if (fetchMore && lastIndex >= 0) {
        url += `/${previousOpinionReducer.opinionList[lastIndex]?.docId}`;
      }
      const res = await axiosInstance.get(url);
      const { data, isEnd, isEmpty } = res.data;
      if (fetchMore) {
        dispatch(
          setOpinionList([...previousOpinionReducer.opinionList, ...data])
        );
      } else {
        dispatch(setOpinionList([...data]));
        dispatch(setOpinionIsEmpty(isEmpty));
      }
      dispatch(setOpinionIsEnd(isEnd));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setOpinionLoading(false));
      dispatch(setOpinionLoadingFilter(false));
    }
  };
};

export const fetchMoreOpinionsList = () => {
  return (dispatch, getState) => {
    const previousOpinionReducer = getState().opinionReducer;
    if (!previousOpinionReducer.isEnd) {
      dispatch(fetchOpinionList(true));
    }
  };
};
export const setDocProperty = (docId, name, val) => {
  return {
    type: SET_OPINION_DOC_PROPERTY,
    payload: {
      name,
      val,
      docId,
    },
  };
};

export const removeDocFromArray = (docId) => {
  return {
    type: REMOVE_OPINION_DOC_ARRAY,
    payload: {
      docId,
    },
  };
};
