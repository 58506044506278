import React, { useMemo } from "react";
import classes from "./speakerXInstitution.module.scss";
import { Button, Spinner } from "../../Utils";
import SpeakerCard from "./Comps/SpeakerCard";

// # COMPONENT
const SpeakerList = ({
  list,
  isShowMoreBtn,
  isEnd,
  loading,
  moreBtnHandler,
  isEmpty,
  selectedFilter,
}) => {
  // THIS IS RENDER
  const _renderList = useMemo(() => {
    return list.map((_o, i) => (
      <SpeakerCard
        key={i}
        {..._o}
        style={{ overflow: "unset" }}
        selectedFilter={selectedFilter}
      />
    ));
  }, [list]);

  return (
    <div className={classes["feeds-container"]}>
      <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
        {_renderList}

        {loading && <Spinner />}

        {isShowMoreBtn && !isEnd && !loading && (
          <div className={classes["more-btn"]}>
            <Button onClick={moreBtnHandler}>MORE</Button>
          </div>
        )}
        {isEmpty && !loading && (
          <p style={{ textAlign: "center" }}>There is no Speaker</p>
        )}
      </div>
    </div>
  );
};

export default SpeakerList;
