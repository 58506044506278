import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { AddonInputField, Button } from "./../../Utils";
import { ReactComponent as UserIcon } from "./../../Assets/user.svg";
import classes from "./opinion.module.scss";
const FormModal = ({
  register,
  errors,
  opinionSubmitHandler,
  handleSubmit,
  formLoader,
  formModalHandler,
  control,
}) => {
  return (
    <form
      onSubmit={handleSubmit(opinionSubmitHandler)}
      className={classes.opinionFormWrapper}
    >
      <AddonInputField
        type="text"
        ref={register}
        name="plantiff"
        leftIcon={UserIcon}
        placeholder="Enter Plantiff"
        required
        error={!!errors.plantiff}
        errorText={errors.plantiff?.message}
      />
      <AddonInputField
        type="text"
        ref={register}
        name="defendant"
        leftIcon={UserIcon}
        placeholder="Enter Defendant"
        required
        error={!!errors.defendant}
        errorText={errors.defendant?.message}
      />
      <AddonInputField
        type="text"
        ref={register}
        name="scoutsOpinion"
        leftIcon={UserIcon}
        placeholder="Enter Opinion Link"
        required
        error={!!errors.scoutsOpinion}
        errorText={errors.scoutsOpinion?.message}
      />
      <AddonInputField
        type="number"
        ref={register}
        name="docketNumber"
        leftIcon={UserIcon}
        placeholder="Enter Docket Number"
        required
        error={!!errors.docketNumber}
        errorText={errors.docketNumber?.message}
        onKeyPress={(event) =>
          event.keyCode === 8 || (event.charCode >= 48 && event.charCode <= 57)
        }
      />
      <AddonInputField
        type="number"
        ref={register}
        name="caseNo"
        leftIcon={UserIcon}
        placeholder="Enter Case Number"
        required
        error={!!errors.caseNo}
        errorText={errors.caseNo?.message}
        onKeyPress={(event) =>
          event.keyCode === 8 || (event.charCode >= 48 && event.charCode <= 57)
        }
      />
      <AddonInputField
        type="date"
        ref={register}
        name="dateOfPublish"
        leftIcon={UserIcon}
        placeholder="Enter Publish Date"
        required
        error={!!errors.dateOfPublish}
        errorText={errors.dateOfPublish?.message}
        // value={publishDate.toISOString().substr(0, 10)}
        // onChange={({ target: { value } }) => setPublishDate(value)}
      />
      {/* <AddonInputField
        type="number"
        ref={register}
        name="termYear"
        leftIcon={UserIcon}
        placeholder="Enter Term Year"
        required
        error={!!errors.termYear}
        errorText={errors.termYear?.message}
      /> */}
      <div className={classes.formDateField}>
        <p>Select term year</p>
        <Controller
          name="termYear"
          control={control}
          render={({ onChange, onBlur, value, name }) => (
            <DatePicker
              name="termYear"
              className={classes.formDateField__datePicker}
              ref={register}
              selected={value}
              onChange={onChange}
              showYearPicker
              dateFormat="yyyy"
              // isClearable
              popperPlacement="top-start"
              placeholderText="Select Term Year"
              minDate={new Date("1/1/2014")}
              maxDate={new Date()}
            />
          )}
        />
        {errors.termYear && (
          <h5 style={{ color: "red" }}>{errors.termYear?.message}</h5>
        )}
      </div>
      <div className={"flex space-bw " + classes.formActionButtons}>
        {!formLoader && (
          <Button type="submit" disabled={formLoader}>
            Create
          </Button>
        )}
        {!formLoader && (
          <Button
            type="button"
            onClick={formModalHandler}
            disabled={formLoader}
            varient="outline"
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

export default FormModal;
