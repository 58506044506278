import React, { useState, useEffect, Fragment } from "react";
import classes from "./status.module.scss";
import { fetchCourtStatus } from "./../../Store/Actions/SettingAction";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "./../../Assets/edit.svg";
import { AddonInputField, Button, FormLoader, Modal } from "../../Utils";
import axiosInstance from "./../../axiosInstance";
import { toast } from "react-toastify";
import IconWrapper from "./../IconWrapper";

const Status = ({ isSidebarOpen, role }) => {
  const [loading, setLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editTextInput, setEditTextInput] = useState("");
  const [modalMsg, setModalMsg] = useState(null);
  const courtStatus = useSelector(
    ({ settingReducer: { courtStatus } }) => courtStatus
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (`${courtStatus}`.length === 0) {
      dispatch(fetchCourtStatus());
    }
  }, []);
  useEffect(() => {
    setEditTextInput(courtStatus);
  }, [courtStatus]);

  const modalToggler = () => setIsEditModalOpen((p) => !p);
  const onTextChange = ({ target: { value } }) => setEditTextInput(value);

  const onUpdateHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axiosInstance.put("/admin/status", {
        status: editTextInput,
      });
      dispatch(fetchCourtStatus());
      modalToggler();
      toast.success("Status Updated.");
    } catch (err) {
      console.log(err, err.response);
      setModalMsg(err.response.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={classes["status"]}>
      <span>{courtStatus}</span>
      {role === "admin" && (
        <Fragment>
          <IconWrapper
            icon={EditIcon}
            onClick={modalToggler}
            className={classes.editIconcls}
          />
          <Modal
            show={isEditModalOpen}
            onClose={modalToggler}
            heading="Update Status"
            modalStyle={{ overflow: "hidden" }}
          >
            <form className={classes["status-form"]} onSubmit={onUpdateHandler}>
              {loading && <FormLoader />}
              <AddonInputField
                value={editTextInput}
                onChange={onTextChange}
                placeholder="Update or Set Status"
                required
              />
              <Button type="submit" disabled={loading}>
                Update
              </Button>
              {modalMsg && (
                <h3 style={{ color: "red", marginTop: "12px" }}>
                  {modalMsg || ""}
                </h3>
              )}
            </form>
          </Modal>
        </Fragment>
      )}
    </div>
  );
};

export default Status;
