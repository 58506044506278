import React from "react";
import classes from "./../components.module.scss";
import Avatar from "../Avatar";
import IconWrapper from "../IconWrapper";
import { ReactComponent as ClockIcon } from "./../../Assets/clock.svg";
import { Link } from "react-router-dom";

import convertDateToSpecificFormat from "./../../Helper/convertDateToSpecificFormat";

const UserRow = (props) => {
  const { varient = "NONE", varientChild = null } = props;

  return (
    <div className={classes.userRowWrapper} style={props.style}>
      <Avatar
        src={props.profilePicture}
        size={52}
        varient={props.badge ? "badge" : "none"}
        badge={props.badge}
        defaultFallbackSrc={`https://firebasestorage.googleapis.com/v0/b/scout-speaks.appspot.com/o/default-profile-picture.jpg?alt=media&cache=${new Date().getTime()}`}
      />

      <div className={classes["info"]}>
        <p>
          {props.firstName} {props.lastName}
        </p>
        <p>@{props.userName}</p>
        <p>{props.legalPosition || "Legal Position Not Given"}</p>
      </div>

      {varient === "NONE" && (
        <div className={classes["time-info"]}>
          <IconWrapper icon={ClockIcon} />
          <p>{convertDateToSpecificFormat(props.createdAt)}</p>
        </div>
      )}
      {varientChild}
    </div>
  );
};

export default UserRow;
