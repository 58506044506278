import React, { useEffect, useState } from "react";
import { number } from "yup";
import { Avatar } from "../../Components";

import { AddonInputField, Button } from "./../../Utils";
import classes from "./badges.module.scss";

const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];

const FormModal = ({
  register,
  errors,
  submitHandler,
  handleSubmit,
  formLoader,
  formModalHandler,
  watch,
}) => {
  const [srcPreview, setSrcPreview] = useState(null);

  useEffect(() => {
    if (watch("src") && watch("src")[0]) {
      if (SUPPORTED_FORMATS.includes(watch("src")[0].type)) {
        let reader = new FileReader();
        reader.onload = function (e) {
          setSrcPreview(e.target.result);
        };
        reader.readAsDataURL(watch("src")[0]);
      } else {
        setSrcPreview(null);
      }
    }
  }, [watch("src")]);
  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      className={classes.formWrapper}
    >
      <div className="flex flex-center">
        {srcPreview && <Avatar src={srcPreview} varient="none" />}
      </div>
      <AddonInputField
        type="file"
        multiple={false}
        ref={register}
        name="src"
        required
        error={!!errors.src}
        errorText={errors.src?.message}
      />
      <AddonInputField
        type="text"
        ref={register}
        name="badgeName"
        placeholder="Enter Badge Name"
        required
        error={!!errors.badgeName}
        errorText={errors.badgeName?.message}
      />
      <div className="flex space-bw">
        {!formLoader && (
          <Button type="submit" disabled={formLoader}>
            Create
          </Button>
        )}
        {!formLoader && (
          <Button
            type="button"
            onClick={formModalHandler}
            disabled={formLoader}
            varient="outline"
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

export default FormModal;
