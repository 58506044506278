import React, { useState } from "react";
import InputField from "./../../Utils/AddonInputField";
import classes from "./changepw.module.scss";
import { Button } from "../../Utils";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import axiosInstance from "./../../axiosInstance";
import { useAuthContext } from "../../Context/AuthContext";

const updatePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Current Password is required")
    .min(6, "Password must be at least 6 characters"),
  password: yup
    .string()
    .required("New Password is required")
    .min(6, "New Password must be at least 6 characters"),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ChangePassword = (props) => {
  const { authState } = useAuthContext();
  const { register, errors, handleSubmit, reset } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const submitHandler = async (data) => {
    try {
      console.log(data);
      setLoading(true);
      const res = await axiosInstance.post("/user/changePassword", { ...data });
      setSuccessMsg("Password Changes Successfully.");
      reset();
    } catch (err) {
      console.log(err.response);
      err.response?.data?.error?.message &&
        setErrorMsg(`${err.response?.data?.error?.message}`.trim());
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <h1>Change Password</h1>
      <h4>My Email is: {authState.userInfo.email}</h4>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={classes["space-y-6"]}>
          <InputField
            name="currentPassword"
            type="password"
            placeholder="Enter Current Password"
            required
            ref={register}
            error={!!errors.currentPassword}
            errorText={errors.currentPassword?.message}
          />
          <InputField
            name="password"
            type="password"
            placeholder="Enter New Password"
            required
            error
            errorText="asd"
            ref={register}
            error={!!errors.password}
            errorText={errors.password?.message}
          />
          <InputField
            name="confirmedPassword"
            type="password"
            placeholder="Enter Confirm New Password"
            required
            ref={register}
            error={!!errors.confirmedPassword}
            errorText={errors.confirmedPassword?.message}
          />

          <Button className={classes["submitBtn"]}>Change Password</Button>
        </div>
        {errorMsg && (
          <p style={{ color: "red", margin: "6px 0px", textAlign: "center" }}>
            {errorMsg}
          </p>
        )}
        {successMsg && (
          <h3
            style={{ color: "green", margin: "6px 0px", textAlign: "center" }}
          >
            {successMsg}
          </h3>
        )}
      </form>
    </div>
  );
};

export default ChangePassword;
