import {
  FETCH_USER_LIST,
  USER_IS_END,
  USER_LOADING,
  USER_IS_EMPTY,
  SET_USER_DOC_PROPERTY,
  ADD_USER_IN_LIST,
} from "./../Actions/UserAction";

const initialState = {
  userList: [],
  isEmpty: false,
  loading: false,
  isEnd: false,
};

const UserReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case USER_IS_END: {
      return {
        ...state,
        ...payload,
      };
    }
    case USER_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case USER_IS_EMPTY: {
      return {
        ...state,
        ...payload,
      };
    }
    case FETCH_USER_LIST: {
      return {
        ...state,
        userList: [...payload.userList],
      };
    }
    case ADD_USER_IN_LIST: {
      return {
        ...state,
        userList: [payload.user, ...payload.userList],
      };
    }
    case SET_USER_DOC_PROPERTY: {
      return setDocProperty(state, payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const setDocProperty = (state, { val, name, docId }) => {
  const listClone = [...state.userList];

  const findIndexOfDoc = listClone.findIndex((_o) => _o.docId === docId);
  if (findIndexOfDoc >= 0) {
    const docClone = { ...listClone[findIndexOfDoc] };
    docClone[name] = val;

    listClone[findIndexOfDoc] = docClone;

    return {
      ...state,
      userList: [...listClone],
    };
  } else {
    return {
      ...state,
    };
  }
};
export default UserReducer;
