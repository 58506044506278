import React, { useEffect, useState } from "react";
import classes from "./../useradmin.module.scss";

import { Button, Card } from "../../../Utils";
import { Avatar, DeleteShower, DropDown, UserRow } from "../../../Components";

import axiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserList,
  setDocProperty,
} from "../../../Store/Actions/UserAction";
import { fetchMoreBadgesList } from "../../../Store/Actions/BadgeAction";
import SuspendedActionBtn from "./SuspendedActionBtn";

const UserCard = (props) => {
  const dispatch = useDispatch();
  const { badgeList } = useSelector(({ badgeReducer }) => badgeReducer);
  const [loading, setLoading] = useState(false);
  const { docId, suspensionStatus } = props;

  const deleteHandler = async () => {
    try {
      const res = await axiosInstance.post(`/admin/deleteUser`, {
        role: "users",
        uid: docId,
      });
      toast.success("User Deleted Successfully.");
      dispatch(fetchUserList());
    } catch (err) {
      console.log(err.response);
    }
  };

  const suspensionHandler = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/admin/suspendUser`, {
        uid: docId,
        role: "user",
      });
      const { data } = res.data;
      toast.success(
        `User ${data.suspensionStatus ? "Suspended" : "Unsuspended"}`
      );
      dispatch(
        setDocProperty(docId, "suspensionStatus", data.suspensionStatus)
      );
    } catch (err) {
      console.log(err, err.response);
    } finally {
      setLoading(false);
    }
  };
  const badgeHandler = async (badgeSrc) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/admin/grantBadge`, {
        uid: docId,
        role: "user",
        src: badgeSrc,
      });
      const { data } = res.data;
      toast.success(`Badge Assigned Successfully.`);
      dispatch(setDocProperty(docId, "badge", badgeSrc));
    } catch (err) {
      console.log(err, err.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={`flex ${classes.card} ${
        suspensionStatus && classes.card__suspended
      }`}
    >
      <UserRow {...props} varient="LIST" />
      <div className={classes.card__actions}>
        <Button onClick={props.onEditClick}>Edit</Button>
        {!loading && (
          <div style={{ width: "180px" }}>
            <DropDown
              optionsList={badgeList}
              placeholder="Assign Badge"
              customItemRender={(value) => {
                return (
                  <li key={value.docId} onClick={() => badgeHandler(value.src)}>
                    <Avatar src={value.src} varient="none" size={34} />
                    <span style={{ marginLeft: "6px" }}>{value.badgeName}</span>
                  </li>
                );
              }}
              loadMore={() => dispatch(fetchMoreBadgesList())}
            />
          </div>
        )}
        <SuspendedActionBtn
          suspensionStatus={suspensionStatus}
          suspensionHandler={suspensionHandler}
          loading={loading}
        />
        <DeleteShower onClick={deleteHandler} />
      </div>
    </Card>
  );
};

export default UserCard;
