import React, { useState, useEffect, useCallback } from "react";
import classes from "./sessions.module.scss";
import Logo from "../../Components/Logo";

import LoginForm from "./component/LoginForm";
import FormLoader from "./../../Utils/FormLoader";
import { useAuthContext } from "../../Context/AuthContext";

const Session = (props) => {
  const { setAuthState } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  // #TAB CHECKER
  let whichTabActive = "login";
  return (
    <div className={classes.container}>
      {/* <Logo /> */}
      <div className={classes.card}>
        <h1 style={{ textAlign: "center", marginTop: "12px" }}>LOGIN</h1>
        <div className={classes.formWrapper}>
          {loading && <FormLoader />}
          {whichTabActive === "login" && (
            <LoginForm
              setLoading={setLoading}
              {...props}
              setErrorMsg={setErrorMsg}
              setSuccessMsg={setSuccessMsg}
            />
          )}
          {errorMsg && (
            <h3
              style={{ color: "red", margin: "6px 0px", textAlign: "center" }}
            >
              {errorMsg}
            </h3>
          )}
          {successMsg && (
            <h3
              style={{ color: "green", margin: "6px 0px", textAlign: "center" }}
            >
              {successMsg}
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default Session;
