import React from "react";
import { Button, Spinner } from "./../../../Utils";

import classes from "./../useradmin.module.scss";

const SuspendedActionBtn = ({
  suspensionStatus,
  suspensionHandler,
  loading,
}) => {
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Button
          varient={suspensionStatus ? "outline" : "NONE"}
          className={suspensionStatus && classes.suspendedBtn}
          onClick={suspensionHandler}
        >
          {suspensionStatus ? "UNSUSPEND" : "SUSPEND"}
        </Button>
      )}
    </div>
  );
};

export default SuspendedActionBtn;
