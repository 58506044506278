import React from "react";
import DeleteShowerComp from "../../Components/DeleteShower";
import DropDown from "../../Components/DropDown/DropDown";

const Home = () => {
  return (
    <div>
      <div>
        asdjh jkdfhc vjkasdyfncvn iasdfcvn iombncvx icxvabnsdf o
        <DropDown />
      </div>
    </div>
  );
};

export default Home;
