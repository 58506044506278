import classes from "./card.module.scss";
import React from "react";

const Card = ({ className, children, ...restProps }) => {
  const classesBuild = `${classes.card} ${className}`;
  return (
    <div className={classesBuild} {...restProps}>
      {children}
    </div>
  );
};

export default Card;
