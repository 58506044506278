import React, { useMemo } from "react";
import classes from "./useradmin.module.scss";

import { Button, Spinner } from "./../../Utils";
import UserCard from "./Comps/UserCard";

// # COMPONENT
const UsersList = ({
  list,
  isShowMoreBtn,
  isEnd,
  loading,
  isEmpty,
  moreBtnHandler,
  onEditClickHandler,
}) => {
  // THIS IS RENDER
  const _renderList = useMemo(() => {
    return list.map((_o, i) => (
      <UserCard
        key={i}
        {..._o}
        style={{ overflow: "unset" }}
        onEditClick={() => onEditClickHandler(_o)}
      />
    ));
  }, [list]);

  return (
    <div className={classes["feeds-container"]}>
      <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
        {_renderList}
        {loading && <Spinner />}

        {isShowMoreBtn && !isEnd && !loading && (
          <div className={classes["more-btn"]}>
            <Button onClick={moreBtnHandler}>MORE</Button>
          </div>
        )}
        {isEmpty && !loading && <p style={{ textAlign: "center" }}>No Users</p>}
      </div>
    </div>
  );
};

export default UsersList;
