import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./useradmin.module.scss";
import axiosInstance from "./../../axiosInstance";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";

import ListComp from "./List";
import SignupForm from "./Comps/SignupForm";
import EditUserProfile from "./EditModal";

import { fetchUserList } from "./../../Store/Actions/UserAction";
import { fetchBadgeList } from "./../../Store/Actions/BadgeAction";
import { RefreshListButton, IconWrapper } from "./../../Components";
import { ReactComponent as PlusIcon } from "./../../Assets/plus.svg";

// FUNCTIONAL COMPONENT
const UsersAdminView = (props) => {
  const dispatch = useDispatch();
  const userReducer = useSelector(({ userReducer }) => userReducer);
  const { isEnd, userList, isEmpty, loading } = userReducer;
  const badgeList = useSelector(({ badgeReducer }) => badgeReducer.badgeList);

  const [showWhichView, setShowWhichView] = useState("");

  const [editUserModal, setEditUserModal] = useState(null);
  useEffect(() => {
    if (userList.length === 0) {
      dispatch(fetchUserList());
    }
    if (badgeList.length === 0) {
      dispatch(fetchBadgeList());
    }
  }, []);

  const moreBtnHandler = () => !isEnd && dispatch(fetchUserList(true));
  // On Scroll reach to bottom fetch more
  // useScrollReachToBottom(moreBtnHandler);

  const onEditClickHandler = ({ docId }) => {
    setEditUserModal(docId);
    setShowWhichView("EDIT_VIEW");
  };
  const closedEditUserModal = () => {
    setShowWhichView("");
    setEditUserModal(null);
  };
  const closedCreateUserModal = () => setShowWhichView("");
  return (
    <div className={classes.profileWrapper}>
      {showWhichView === "" && (
        <Fragment>
          <h1 className="text-center" style={{ fontFamily: "Ubuntu" }}>
            Users
          </h1>
          <RefreshListButton onClick={() => dispatch(fetchUserList())} />
          {/* <span style={{ margin: "auto" }}>
            <IconWrapper
              icon={PlusIcon}
              onClick={() => setShowWhichView("CREATE_VIEW")}
            />
          </span> */}
        </Fragment>
      )}
      {showWhichView === "NOT_SHOWING_X" && (
        <SignupForm closedCreateUserModal={closedCreateUserModal} />
      )}
      {showWhichView === "EDIT_VIEW" && (
        <EditUserProfile
          userEditId={editUserModal}
          closedEditUserModal={closedEditUserModal}
        />
      )}
      {showWhichView === "" && (
        <ListComp
          list={userList}
          isShowMoreBtn={!isEnd}
          isEnd={isEnd}
          loading={loading}
          moreBtnHandler={moreBtnHandler}
          isEmpty={isEmpty}
          onEditClickHandler={onEditClickHandler}
        />
      )}
    </div>
  );
};

export default UsersAdminView;
