import {
  FEETCH_POSTS_LIST,
  POST_LOADING,
  POST_IS_END,
  POST_IS_EMPTY,
  SET_POST_DOC_PROPERTY,
} from "./../Actions/PostsAction";

const initialState = {
  list: [],
  isEmpty: false,
  loading: false,
  isEnd: false,
};

const speakerReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case POST_IS_END: {
      return {
        ...state,
        ...payload,
      };
    }
    case POST_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case POST_IS_EMPTY: {
      return {
        ...state,
        ...payload,
      };
    }
    case FEETCH_POSTS_LIST: {
      return {
        ...state,
        list: [...payload.list],
      };
    }
    case SET_POST_DOC_PROPERTY: {
      return setDocProperty(state, payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const setDocProperty = (state, { val, name, docId }) => {
  const listClone = [...state.list];

  const findIndexOfDoc = listClone.findIndex((_o) => _o.docId === docId);

  if (findIndexOfDoc >= 0) {
    const docClone = { ...listClone[findIndexOfDoc] };
    docClone[name] = val;

    listClone[findIndexOfDoc] = docClone;

    return {
      ...state,
      list: [...listClone],
    };
  } else {
    return {
      ...state,
    };
  }
};
export default speakerReducer;
