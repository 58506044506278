import React, { useMemo } from "react";
import classes from "./badges.module.scss";

import { Button, Spinner } from "./../../Utils";
import BadgeCard from "./BadgeCard";

// # COMPONENT
const BadgesList = ({
  badgeList,
  isShowMoreBtn,
  isEnd,
  loading,
  moreBtnHandler,
  onEditClick,
}) => {
  // THIS IS RENDER
  const _renderList = useMemo(() => {
    return badgeList.map((_o, i) => (
      <BadgeCard
        key={i}
        {..._o}
        onEditClick={() => onEditClick(_o)}
        style={{ overflow: "unset" }}
      />
    ));
  }, [badgeList]);

  return (
    <div className={classes["feeds-container"]}>
      <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
        {_renderList}
        {loading && (
          <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
        )}

        {isShowMoreBtn && !isEnd && !loading && (
          <div className={classes["more-btn"]}>
            <Button onClick={moreBtnHandler}>MORE</Button>
          </div>
        )}
        {isEnd && <p style={{ textAlign: "center" }}>No more badges</p>}
      </div>
    </div>
  );
};

export default BadgesList;
