import {
  ADD_BADGE,
  REMOVE_BADGE,
  FETCH_BADGE_LIST,
  BADGE_IS_END,
  BADGE_LOADING,
  BADGE_IS_EMPTY,
  SET_BADGE_DOC_PROPERTY,
  REMOVE_BADGE_DOC_ARRAY,
} from "./../Actions/BadgeAction";

const initialState = {
  badgeList: [],
  isEmpty: false,
  loading: false,
  isEnd: false,
};

const BagdeReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case BADGE_IS_END: {
      return {
        ...state,
        ...payload,
      };
    }
    case BADGE_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case BADGE_IS_EMPTY: {
      return {
        ...state,
        ...payload,
      };
    }
    case FETCH_BADGE_LIST: {
      return {
        ...state,
        badgeList: [...payload.badgeList],
      };
    }
    case SET_BADGE_DOC_PROPERTY: {
      return setDocProperty(state, payload);
    }
    case REMOVE_BADGE_DOC_ARRAY: {
      return removeDocList(state, payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const setDocProperty = (state, { val, name, docId }) => {
  const badgeListClone = [...state.badgeList];

  const findIndexOfDoc = badgeListClone.findIndex((_o) => _o.docId === docId);

  if (findIndexOfDoc >= 0) {
    const docClone = { ...badgeListClone[findIndexOfDoc] };
    docClone[name] = val;

    badgeListClone[findIndexOfDoc] = docClone;

    return {
      ...state,
      badgeList: [...badgeListClone],
    };
  } else {
    return {
      ...state,
    };
  }
};
const removeDocList = (state, { docId }) => {
  const badgeListClone = [...state.badgeList];

  const filteredList = badgeListClone.filter((_o) => _o.docId !== docId);

  return {
    ...state,
    badgeList: [...filteredList],
  };
};
export default BagdeReducer;
