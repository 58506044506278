import { Main as MainLayout } from "./Layout";
import {
  SessionView,
  Home,
  OpninonsView,
  ChangePasswordView,
  BadgeView,
  UserAdminView,
  InstitutionManageByAdminView,
  InstitutionHome,
  EditProfileView,
  SpeakerXInstitutionView,
  AdminPosts,
} from "./Views";

export const routesList = [
  {
    id: "login",
    path: "/",
    component: SessionView,
    layout: MainLayout,
    setting: {
      header: true,
      sidebar: false,
    },
  },
  {
    id: "home",
    path: "/",
    component: Home,
    layout: MainLayout,
    setting: {},
  },
  {
    id: "badge",
    path: "/badges",
    component: BadgeView,
    layout: MainLayout,
    setting: {},
  },
  {
    id: "opinion",
    path: "/opinion",
    component: OpninonsView,
    layout: MainLayout,
    setting: {},
  },
  {
    id: "manage-user-admin",
    path: "/manage-users",
    component: UserAdminView,
    layout: MainLayout,
    setting: {},
  },
  {
    id: "manage-institution-admin",
    path: "/manage-institutions",
    component: InstitutionManageByAdminView,
    layout: MainLayout,
    setting: {},
  },
  {
    id: "institution-home",
    path: "/",
    component: InstitutionHome,
    layout: MainLayout,
    setting: {
      header: true,
      sidebar: true,
    },
  },
  {
    id: "change-password",
    path: "/change-password",
    component: ChangePasswordView,
    layout: MainLayout,
    setting: {},
  },
  {
    id: "edit-profile",
    path: "/i/edit",
    component: EditProfileView,
    layout: MainLayout,
    setting: {},
  },
  {
    id: "speakers",
    path: "/i/speakers",
    component: SpeakerXInstitutionView,
    layout: MainLayout,
    setting: {
      header: true,
      sidebar: true,
    },
  },
  {
    id: "posts",
    path: "/posts",
    component: AdminPosts,
    layout: MainLayout,
    setting: {
      header: true,
      sidebar: true,
    },
  },
];

export const publicRoutesId = ["login"];
export const institutionRoutesId = [
  "change-password",
  "edit-profile",
  "speakers",
];
export const adminRoutesId = [
  "opinion",
  "badge",
  "posts",
  "manage-user-admin",
  "manage-institution-admin",
  "change-password",
];
