import React from "react";
import classes from "./../components.module.scss";

import IconWrapper from "./../IconWrapper";
import { Button } from "./../../Utils";
import DeleteConfirmationWrapper from "./../DeleteShower/index";
import { ReactComponent as EditIcon } from "./../../Assets/edit.svg";
import convertDateToSpecificFormat from "./../../Helper/convertDateToSpecificFormat";
import axiosInstance from "./../../axiosInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { removeDocFromArray } from "./../../Store/Actions/OpinionAction";
const OpinonCard = ({
  className = "",
  varient = "single",
  scoutsOpinion,
  docketNumber,
  dateOfPublish,
  docId,
  defendant,
  plantiff,
  onEditClick,
  termYear,
  style = {},
}) => {
  const dispatch = useDispatch();

  const deleteHandler = async () => {
    try {
      const res = await axiosInstance.delete(`/admin/opinions/${docId}`);
      toast.success("Deleted Successfully.");
      dispatch(removeDocFromArray(docId));
    } catch (err) {
      console.log(err.response);
    }
  };
  return (
    <div
      style={style}
      className={`${classes.opinonCardWrapper} ${className} ${
        ["single", "update", "delete"].includes(varient) &&
        classes["opinonCardWrapper-single"]
      }`}
    >
      <p className={classes["vs-txt"]}>
        {plantiff}
        <span> vs </span> {defendant}
      </p>
      <Button
        style={{
          backgroundColor: "transparent",
          color: "var(--blue-d)",
          width: "auto",
          padding: "0px 12px",
          height: "38px",
          textAlign: "center",
          margin: "12px",
          border: "solid 2px var(--color-l)",
        }}
        onClick={() => window.open(scoutsOpinion)}
      >
        Read Full Opinion Here
      </Button>
      <p>Orig. Pub. {convertDateToSpecificFormat(dateOfPublish)}</p>
      <p>Docket# {docketNumber}</p>
      <p>Term Year# {termYear}</p>
      {["single", "update"].includes(varient) && (
        <IconWrapper
          styleIcon={{ fill: "var(--gray-dark)" }}
          onClick={onEditClick}
          icon={EditIcon}
          className={classes["composeBtn"]}
        />
      )}
      {["delete"].includes(varient) && (
        <DeleteConfirmationWrapper
          className={classes["composeBtn"]}
          onClick={deleteHandler}
        />
      )}
    </div>
  );
};

export default OpinonCard;
