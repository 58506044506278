import React from "react";
import { Button, Modal } from "../../Utils";
import classes from "./useradmin.module.scss";
import IconWrapper from "./../../Components/IconWrapper";
import { ReactComponent as PlusIcon } from "./../../Assets/plus.svg";
import FormModal from "./FormModal";
import FormLoader from "./../../Utils/FormLoader";
import { UpdateUsername } from "./../../Components";

const CreateInstitution = ({
  showFormModal,
  formModalHandler,
  register,
  handleSubmit,
  errors,
  submitHandler,
  formLoader,
  watch,
  username,
  onUsernameChange,
  setIsValidUsername,
  modalCloseHandler,
  formMsg,
  control,
}) => {
  return (
    <div className="flex flex-center">
      <IconWrapper icon={PlusIcon} onClick={formModalHandler} />
      <Modal
        show={showFormModal}
        onClose={formModalHandler}
        heading="Create Institution"
        modalStyle={{ height: "560px" }}
      >
        {formLoader && <FormLoader />}
        <FormModal
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          submitHandler={submitHandler}
          formLoader={formLoader}
          modalCloseHandler={modalCloseHandler}
          username={username}
          onUsernameChange={onUsernameChange}
          setIsValidUsername={setIsValidUsername}
          formMsg={formMsg}
          control={control}
        />
      </Modal>
    </div>
  );
};

export default CreateInstitution;
