import React, { useEffect, useState } from "react";
import classes from "./badges.module.scss";

import { AddonInputField, Button, Card, Spinner } from "./../../Utils";
import { DeleteShower, IconWrapper, UploadAvatar } from "./../../Components";
import { ReactComponent as EditIcon } from "./../../Assets/edit.svg";
import axiosInstance from "./../../axiosInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  fetchBadgeList,
  setDocProperty,
} from "../../Store/Actions/BadgeAction";

const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];

const BadgeCard = ({ badgeName, src, docId, fileName }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [badgeNameInput, setBadgeNameInput] = useState({
    value: badgeName,
    error: null,
    loading: false,
    show: false,
  });

  useEffect(() => {
    onInputNameStateChange("value", badgeName);
  }, [badgeName]);
  const deleteHandler = async () => {
    try {
      const res = await axiosInstance.post(`/admin/deleteBadge`, {
        badgeId: docId,
      });
      toast.success("Deleted Successfully.");
      dispatch(fetchBadgeList());
    } catch (err) {
      console.log(err.response);
    }
  };
  const badgeImgReupload = async (e) => {
    let selected = e.target.files[0];
    console.log(selected);
    if (selected && SUPPORTED_FORMATS.includes(selected.type)) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("file", selected);
        formData.append("Content-Type", "application/json");
        const res = await axiosInstance.post(
          `/admin/reuploadBadge/${docId}/${fileName}`,
          formData
        );
        const { data } = res.data;
        console.log(data);
        toast.success("Badge Image Uploaded Successfully");
        dispatch(
          setDocProperty(
            docId,
            "src",
            `${data.src}&cache=${new Date().getTime()}`
          )
        );
        dispatch(setDocProperty(docId, "fileName", `${data.fileName}`));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const onInputNameStateChange = (name, value) => {
    setBadgeNameInput((_p) => ({ ..._p, error: null, [name]: value }));
  };
  const inputFieldShowToggler = () => {
    setBadgeNameInput((_p) => ({ ..._p, show: !_p.show }));
  };

  const onUpdateBadgeName = async (e) => {
    e.preventDefault();
    if (badgeNameInput.value.length <= 0) {
      onInputNameStateChange("error", "Name must be required.");
      return;
    }
    try {
      onInputNameStateChange("loading", true);
      const res = await axiosInstance.post(`/admin/badgeNameUpdate/${docId}`, {
        badgeName: badgeNameInput.value,
      });
      toast.success("Badge Name Updated Successfully.");
      dispatch(setDocProperty(docId, "badgeName", `${badgeNameInput.value}`));
    } catch (err) {
      console.log(err.response);
    } finally {
      onInputNameStateChange("loading", false);
      onInputNameStateChange("show", false);
    }
  };
  const onBadgeNameInputCancel = () => {
    setBadgeNameInput({
      value: badgeName,
      error: null,
      loading: false,
      show: false,
    });
  };

  return (
    <Card className={`flex ${classes.badgeCard}`}>
      <UploadAvatar
        src={src}
        // varient="none"
        size={56}
        onImageInputChange={badgeImgReupload}
        loading={loading}
      />
      <div className={classes.badgeCard__nameInput}>
        <form onSubmit={onUpdateBadgeName}>
          {badgeNameInput.show ? (
            <>
              <AddonInputField
                value={badgeNameInput.value}
                onChange={(e) =>
                  onInputNameStateChange("value", e.target.value)
                }
                error={!!badgeNameInput.error}
                errorText={badgeNameInput.error}
                disabled={badgeNameInput.loading}
              />
              {badgeNameInput.loading ? (
                <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
              ) : (
                <>
                  <Button type="submit">update</Button>
                  <Button type="button" onClick={onBadgeNameInputCancel}>
                    cancel
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <p>{badgeNameInput.value}</p>
              <IconWrapper icon={EditIcon} onClick={inputFieldShowToggler} />
            </>
          )}
        </form>
      </div>
      <div className={classes.badgeCard__actions}>
        <DeleteShower onClick={deleteHandler} />
      </div>
    </Card>
  );
};

export default BadgeCard;
