import React, { useState } from "react";
import { Link } from "react-router-dom";

import classes from "./../mainlayout.module.scss";
import Logo from "../../../Components/Logo";
import IconWrapper from "../../../Components/IconWrapper";

import { ReactComponent as navIcon } from "./../../../Assets/ic_Home.svg";
import { ReactComponent as searchIcon } from "./../../../Assets/search.svg";

import { useUiSettings } from "./../../../Context/UiContext";
import SearchBar from "../../../Components/SearchBar";
const Header = (props) => {
  const { sidebarOpenToggler } = useUiSettings();

  const [isSearchBarOpen, setSearchBarOpen] = useState(false);

  const searchBarToggler = () => {
    setSearchBarOpen((p) => !p);
  };

  return (
    <header className={classes.header}>
      {props.sidebar && (
        <IconWrapper
          className={classes.headerSidebarTogglerBtn}
          style={{ marginLeft: "6px" }}
          icon={navIcon}
          onClick={sidebarOpenToggler}
        />
      )}
      <Link to="/" className={classes.logo}>
        <Logo />
      </Link>

      <SearchBar isOpen={isSearchBarOpen} onClosed={searchBarToggler} />
    </header>
  );
};

export default Header;
