import axiosInstance from "./../../axiosInstance";

export const FEETCH_POSTS_LIST = "FETCH_INSTITUTION_SPEAKERS_LIST";
export const POST_LOADING = "SPEAKER_LOADING";
export const POST_IS_END = "SPEAKER_IS_END";
export const POST_IS_EMPTY = "SPEAKER_IS_EMPTY";
export const SET_POST_DOC_PROPERTY = "SET_SPEAKER_DOC_PROPERTY";

export const setSpeakerAcLoading = (val) => {
  return {
    type: POST_LOADING,
    payload: {
      loading: val,
    },
  };
};
export const setSpeakerAcIsEnd = (val) => {
  return {
    type: POST_IS_END,
    payload: {
      isEnd: val,
    },
  };
};
export const setSpeakerAcIsEmpty = (val) => {
  return {
    type: POST_IS_EMPTY,
    payload: {
      isEmpty: val,
    },
  };
};
export const setSpeakerAcList = (val) => {
  return {
    type: FEETCH_POSTS_LIST,
    payload: {
      list: val,
    },
  };
};
export const deletePost = (id) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().postReducer;
      dispatch(setSpeakerAcLoading(true));
      let url = "admin/deletePost";
      const res = await axiosInstance.post(url, { uid: id });
      const { message, success } = res.data;
      if (success === true) {
        const shallow = [...previousReducerState.list];
        const newArr = shallow.filter((val) => val.docId !== id);
        dispatch(setSpeakerAcList([...newArr]));
      }
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setSpeakerAcLoading(false));
    }
  };
};
export const hidePost = (id) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().postReducer;
      dispatch(setSpeakerAcLoading(true));
      let url = "admin/hidePost";
      const res = await axiosInstance.post(url, { uid: id });
      const { message, success, hiddenStatus } = res.data;
      if (success === true) {
        const shallow = [...previousReducerState.list];
        const newArr = shallow.map((val) => {
          if (val.docId === id) {
            val.hidden = hiddenStatus;
          }
          return val;
        });
        dispatch(setSpeakerAcList([...newArr]));
      }
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setSpeakerAcLoading(false));
    }
  };
};

export const fetchSpeakerAcList = (fetchMore) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().postReducer;
      dispatch(setSpeakerAcLoading(true));
      let url = "/admin/getReportedPosts";
      const lastIndex = previousReducerState.list.length - 1;
      if (fetchMore && lastIndex >= 0) {
        url += `/${previousReducerState.list[lastIndex]?.docId}`;
      }
      const res = await axiosInstance.get(url);
      const { data, isEnd, isEmpty } = res.data;
      if (fetchMore) {
        dispatch(setSpeakerAcList([...previousReducerState.list, ...data]));
      } else {
        dispatch(setSpeakerAcList([...data]));
        dispatch(setSpeakerAcIsEmpty(isEmpty));
      }
      dispatch(setSpeakerAcIsEnd(isEnd));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setSpeakerAcLoading(false));
    }
  };
};

export const setDocProperty = (docId, name, val) => {
  return {
    type: SET_POST_DOC_PROPERTY,
    payload: {
      name,
      val,
      docId,
    },
  };
};
