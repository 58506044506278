export const adminNavLinks = [
  {
    path: "/badges",
    title: "Manage Badges",
  },
  {
    path: "/opinion",
    title: "Opinion",
  },
  {
    path: "/posts",
    title: "Posts",
  },
  {
    path: "/manage-users",
    title: "Users",
  },
  {
    path: "/manage-institutions",
    title: "Institutions",
  },
];
export const institutionNavLinks = [
  {
    path: "/i/speakers",
    title: "Speakers",
  },
  {
    path: "/i/edit",
    title: "Edit Profile",
  },
];
