import React from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import classes from "./mainlayout.module.scss";

const Main = (props) => {
  const {
    children,
    setting: { header = true, sidebar = true },
  } = props;
  return (
    <div className={classes.mainContainer}>
      {sidebar && <Sidebar />}
      <div className={`${sidebar && classes.babyContent}`}>
        {header && <Header sidebar={sidebar} />}
        <div className={classes.contentContaienr}>{children}</div>
      </div>
    </div>
  );
};

export default Main;
