import axiosInstance from "./../../axiosInstance";

export const FETCH_INSTITUTION_SPEAKERS_LIST =
  "FETCH_INSTITUTION_SPEAKERS_LIST";

export const SPEAKER_LOADING = "SPEAKER_LOADING";
export const SPEAKER_IS_END = "SPEAKER_IS_END";
export const SPEAKER_IS_EMPTY = "SPEAKER_IS_EMPTY";
export const SET_SPEAKER_DOC_PROPERTY = "SET_SPEAKER_DOC_PROPERTY";

export const setSpeakerAcLoading = (val) => {
  return {
    type: SPEAKER_LOADING,
    payload: {
      loading: val,
    },
  };
};
export const setSpeakerAcIsEnd = (val) => {
  return {
    type: SPEAKER_IS_END,
    payload: {
      isEnd: val,
    },
  };
};
export const setSpeakerAcIsEmpty = (val) => {
  return {
    type: SPEAKER_IS_EMPTY,
    payload: {
      isEmpty: val,
    },
  };
};
export const setSpeakerAcList = (val) => {
  return {
    type: FETCH_INSTITUTION_SPEAKERS_LIST,
    payload: {
      list: val,
    },
  };
};

export const fetchSpeakerAcList = (fetchMore, filterScope = null) => {
  return async (dispatch, getState) => {
    try {
      const previousReducerState = getState().speakerReducer;
      dispatch(setSpeakerAcLoading(true));
      let url = "/institution/speakers";
      const lastIndex = previousReducerState.list.length - 1;
      if (fetchMore && lastIndex >= 0) {
        url += `/${previousReducerState.list[lastIndex]?.docId}`;
      }
      const filterOptions = {
        scope: null,
      };
      if (["alumni", "faculty", "student", null].includes(filterScope)) {
        filterOptions["scope"] = filterScope;
      }
      if (["declined", "pending"].includes(filterScope)) {
        filterOptions["scope"] = null;
        filterOptions["approvalStatus"] = filterScope;
      } else {
        filterOptions["approvalStatus"] = "approved";
      }

      const res = await axiosInstance.post(url, { ...filterOptions });
      const { data, isEnd, isEmpty } = res.data;
      if (fetchMore) {
        dispatch(setSpeakerAcList([...previousReducerState.list, ...data]));
      } else {
        dispatch(setSpeakerAcList([...data]));
        dispatch(setSpeakerAcIsEmpty(isEmpty));
      }
      dispatch(setSpeakerAcIsEnd(isEnd));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setSpeakerAcLoading(false));
    }
  };
};

export const setDocProperty = (docId, name, val) => {
  return {
    type: SET_SPEAKER_DOC_PROPERTY,
    payload: {
      name,
      val,
      docId,
    },
  };
};
