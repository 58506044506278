import React from "react";
import { Link, NavLink } from "react-router-dom";
import SidebarLayout from "../../../Utils/SiderbarLayout";
import classes from "./../mainlayout.module.scss";
import Status from "../../../Components/Status";
import { useUiSettings } from "../../../Context/UiContext";

import { ReactComponent as SettingIcon } from "./../../../Assets/cog.svg";
import { ReactComponent as LogoutIcon } from "./../../../Assets/door-open.svg";
import { IconWrapper, SocialButtons } from "../../../Components";
import { useAuthContext } from "../../../Context/AuthContext";

import { adminNavLinks, institutionNavLinks } from "./NavLinks";
import momentTimeZone from "moment-timezone";
import useNewTimer from "./../../../Hooks/useNewTimer";

const Sidebar = (props) => {
  const { isSidebarOpen, sidebarOpenToggler } = useUiSettings();
  const { logout, authState } = useAuthContext();
  const { userInfo, role } = authState;
  const logoutHandler = () => {
    sidebarOpenToggler();
    logout();
  };
  const clockTimer = useNewTimer(
    momentTimeZone().tz("America/New_York").format("LTS"),
    () => momentTimeZone().tz("America/New_York").format("LTS")
  );
  // # NAV LINKS ON ROLE
  let navLinks = [];
  if (role === "admin") {
    navLinks = [...adminNavLinks];
  } else if (role === "institution") {
    navLinks = [...institutionNavLinks];
  }
  return (
    <SidebarLayout>
      <div className={classes.sidebar + " styleScroll "}>
        <p className={classes["title"]}>Hello,</p>
        <p className={classes["info"]}>{role}</p>
        <p>Washington D.C. - United States</p>
        <p>{clockTimer}</p>
        <Status isSidebarOpen={isSidebarOpen} role={role} />

        {/* # RENDER NAV LINKS HERE # */}
        <div className={classes["navLinkWrapper"]}>
          {navLinks.map((navObj, _i) => {
            const { title, path } = navObj;

            return (
              <NavLink
                exact
                key={_i}
                activeClassName={classes["activeLink"]}
                to={path}
                onClick={sidebarOpenToggler}
              >
                {title}
              </NavLink>
            );
          })}
        </div>

        <div className={`${classes["iconWrapper"]} ${classes["space-x"]}`}>
          <Link to="/change-password" onClick={sidebarOpenToggler}>
            <IconWrapper className={classes["i-bstn"]} icon={SettingIcon} />
          </Link>
          <IconWrapper
            onClick={logoutHandler}
            className={classes["i-bstn"]}
            icon={LogoutIcon}
          />
        </div>

        <div className={classes["bottom-info"]}>
          <SocialButtons />
          <p>
            <Link to="/terms">Terms and Conditions</Link>
            {"  "}
            <Link to="/terms">Privacy Policy</Link>
          </p>
          <p>SCOTUS SPEAKS by Lawtrix</p>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default Sidebar;
