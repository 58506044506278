import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import axiosInstance from "./../../../axiosInstance";

import { useAuthContext } from "../../../Context/AuthContext";
import classes from "./../useradmin.module.scss";
import { ReactComponent as UserIcon } from "./../../../Assets/user.svg";
import { ReactComponent as LockIcon } from "./../../../Assets/lock.svg";
import { ReactComponent as BackArrow } from "./../../../Assets/arrow-left.svg";
import {
  UpdateUsername,
  InstitutionSelector,
  IconWrapper,
} from "./../../../Components";
import {
  AddonInputField,
  Button,
  AddonSelectorField,
  FormLoader,
} from "./../../../Utils";
import { addUserInList } from "./../../../Store/Actions/UserAction";
import { useDispatch } from "react-redux";
const signupSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Invalid Email")
    // .matches(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/, "Invalid Email")
    .required("Email must not be empty"),
  password: yup
    .string()
    .min(6, "New Password must be at least 6 characters")
    .required("New Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  institutionName: yup.string().required("Please Select Institution"),
  institutionUsername: yup.string().required("Please Select Institution"),
  lawSchool: yup.string().required("Please Select Institution"),
  legalPosition: yup.string().required("Please Select legal Position"),
});

const SignupForm = (props) => {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(signupSchema),
  });
  const [username, setUsername] = useState("");
  const [isValidUsername, setIsValidUsername] = useState(false);

  const [
    showInstitutionSelectorModal,
    setShowInstitutionSelectorModal,
  ] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  // # COMPONENT FUNCTIONS.
  const onSubmitHandler = async (formData) => {
    if (loading) {
      return;
    }
    try {
      if (!isValidUsername) {
        setErrorMsg("Please select Valid Username");
        return;
      }
      setErrorMsg(null);
      setLoading(true);
      const resData = await axiosInstance.post("/admin/createUser", {
        userName: username,
        ...formData,
      });
      console.log(resData);
      const { data } = resData;
      if (data) {
        setLoading(false);
        setSuccessMsg("User Created.");
        setTimeout(() => {
          props.closedCreateUserModal();
          dispatch(addUserInList(data.userData));
        }, 1000);
      }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      err.response?.data?.error?.message &&
        setErrorMsg(`${err.response?.data?.error?.message}`.trim());
    }
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value?.toLowerCase());
  };

  const selectorModalHandler = () => setShowInstitutionSelectorModal((p) => !p);

  const onSelectInstitution = ({
    institutionName,
    institutionUsername,
    id,
  }) => {
    setValue("institutionName", institutionName, { shouldValidate: true });
    setValue("institutionUsername", institutionUsername);
    setValue("lawSchool", "BKnV3zu3iiOSPq9nVK3DWsS45aj2");
    selectorModalHandler();
  };

  return (
    <div className={classes.formWrapper}>
      <h2 style={{ fontFamily: "Ubuntu", textAlign: "center" }}>Create User</h2>
      <IconWrapper
        icon={BackArrow}
        className={classes.container__backArrow}
        onClick={props.closedCreateUserModal}
      />
      <InstitutionSelector
        isOpen={showInstitutionSelectorModal}
        onClosed={selectorModalHandler}
        onSelectHandler={onSelectInstitution}
      />
      <div className={classes.fieldsWrapper}>
        {loading && <FormLoader />}
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <UpdateUsername
            userName={username}
            onChange={onUsernameChange}
            varient="CHECK_VALIDATION"
            setIsValidUsername={setIsValidUsername}
            leftIcon={UserIcon}
          />
          <AddonInputField
            ref={register}
            name="firstName"
            leftIcon={UserIcon}
            placeholder="First Name"
            required
            error={!!errors.firstName}
            errorText={errors.firstName?.message}
          />
          <AddonInputField
            ref={register}
            name="lastName"
            leftIcon={UserIcon}
            placeholder="Last Name"
            required
            error={!!errors.lastName}
            errorText={errors.lastName?.message}
          />
          <AddonSelectorField
            // className={classes["border-out"]}
            ref={register}
            leftIcon={UserIcon}
            name="legalPosition"
            placeholder="Select Legal Position"
            required
            error={!!errors.instType}
            errorText={errors.instType?.message}
            options={[
              { value: "JD Candidate", text: "JD Candidate" },
              { value: "LLM Candidate", text: "LLM Candidate" },
              { value: "Educational Faculty", text: "Educational Faculty" },
              { value: "Practicing Attorney", text: "Practicing Attorney" },
            ]}
          />

          <AddonInputField
            ref={register}
            name="institutionName" //THIS IS THE INSTITUTION ID.
            leftIcon={UserIcon}
            placeholder="Select Institution"
            required
            onClick={selectorModalHandler}
            error={!!errors.institutionName}
            errorText={errors.institutionName?.message}
            // readOnly={true}
          />
          <AddonInputField
            containerStyle={{ display: "none" }}
            ref={register}
            name="institutionUsername" //THIS IS THE INSTITUTION ID.
            leftIcon={UserIcon}
            placeholder="Select Institution"
            required
            error={!!errors.lawSchool}
            errorText={errors.lawSchool?.message}
            readOnly={true}
          />
          <AddonInputField
            containerStyle={{ display: "none" }}
            ref={register}
            name="lawSchool" //THIS IS THE INSTITUTION ID.
            leftIcon={UserIcon}
            placeholder="Select Institution"
            required
            error={!!errors.lawSchool}
            errorText={errors.lawSchool?.message}
            readOnly={true}
          />
          <AddonInputField
            ref={register}
            name="email"
            leftIcon={UserIcon}
            placeholder="Email"
            required
            error={!!errors.email}
            errorText={errors.email?.message}
          />
          <AddonInputField
            ref={register}
            name="password"
            leftIcon={LockIcon}
            placeholder="Password"
            type="password"
            required
            error={!!errors.password}
            errorText={errors.password?.message}
          />
          <AddonInputField
            ref={register}
            name="confirmPassword"
            leftIcon={LockIcon}
            placeholder="Confirm Password"
            type="password"
            required
            error={!!errors.confirmPassword}
            errorText={errors.confirmPassword?.message}
          />
          <div className={classes.submissionButtonsGroup}>
            <Button
              type="submit"
              style={{ backgroundColor: "#0A0841" }}
              disabled={loading}
            >
              Create User
            </Button>
          </div>
        </form>
      </div>{" "}
      {errorMsg && (
        <h3 style={{ color: "red", margin: "6px 0px", textAlign: "center" }}>
          {errorMsg}
        </h3>
      )}
      {successMsg && (
        <h3 style={{ color: "green", margin: "6px 0px", textAlign: "center" }}>
          {successMsg}
        </h3>
      )}
    </div>
  );
};

export default SignupForm;
