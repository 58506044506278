import React, { useCallback, useMemo, useRef, useState } from "react";
import classes from "./dropdown.module.scss";

import { ReactComponent as AngleDownIcon } from "./../../Assets/angle-down.svg";
import useOnClickOutside from "./../../Hooks/useOnClickOutside";

const DropDown = ({
  optionsList = [...Array(50).fill(2)],
  customItemRender = null,
  onChange = () => null,
  placeholder = "Please Select",
  loadMore = () => null,
}) => {
  const listContainerRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [selectOption, setSelctionOption] = useState(null);
  const optionShowToggler = () => setShowOptions((_p) => !_p);
  const outsideClickCloser = useCallback(() => {
    if (showOptions) {
      setShowOptions(false);
    }
  }, [showOptions]);
  useOnClickOutside(listContainerRef, outsideClickCloser);
  const handleScroll = (event) => {
    const target = event.target;

    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      loadMore();
    }
  };

  const onRenderItemClick = (v) => {
    optionShowToggler();
    onChange(v);
    setSelctionOption(v);
  };
  const renderItem = (_o, i) => (
    <li key={i} onClick={() => onRenderItemClick(_o)}>
      Value {i + 1}
    </li>
  );

  const renderSelectedItem = useMemo(() => {
    if (!selectOption) {
      return <li>{placeholder} </li>;
    }

    return customItemRender ? customItemRender(selectOption) : <li>gjh</li>;
  }, [selectOption]);

  return (
    <div ref={listContainerRef} className={classes.dropdownWrapper}>
      <div
        role="button"
        className={classes.selectValueWrapper}
        onClick={optionShowToggler}
      >
        {renderSelectedItem}
        <AngleDownIcon className={classes.dropdownIcon} />
      </div>
      {showOptions && (
        <ul onScroll={handleScroll} onClick={optionShowToggler}>
          {optionsList.map(customItemRender ? customItemRender : renderItem)}
        </ul>
      )}
    </div>
  );
};

export default DropDown;
