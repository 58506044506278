import axiosInstance from "./../../axiosInstance";

export const ADD_BADGE = "ADD_BADGE";
export const REMOVE_BADGE = "REMOVE_BADGE";
export const FETCH_BADGE_LIST = "FETCH_BADGE_LIST";

export const BADGE_LOADING = "BADGE_LOADING";
export const BADGE_IS_END = "BADGE_IS_END";
export const BADGE_IS_EMPTY = "BADGE_IS_EMPTY";

export const SET_BADGE_DOC_PROPERTY = "SET_BADGE_DOC_PROPERTY";
export const REMOVE_BADGE_DOC_ARRAY = "REMOVE_BADGE_DOC_ARRAY";

export const setBadgeLoading = (val) => {
  return {
    type: BADGE_LOADING,
    payload: {
      loading: val,
    },
  };
};
export const setBadgeIsEnd = (val) => {
  return {
    type: BADGE_IS_END,
    payload: {
      isEnd: val,
    },
  };
};
export const setBadgeIsEmpty = (val) => {
  return {
    type: BADGE_IS_EMPTY,
    payload: {
      isEmpty: val,
    },
  };
};
export const setBadgeList = (val) => {
  return {
    type: FETCH_BADGE_LIST,
    payload: {
      badgeList: val,
    },
  };
};

export const fetchBadgeList = (fetchMore) => {
  return async (dispatch, getState) => {
    try {
      const previousBadgeReducer = getState().badgeReducer;
      dispatch(setBadgeLoading(true));
      let url = "/admin/badges";
      const lastIndex = previousBadgeReducer.badgeList.length - 1;
      if (fetchMore && lastIndex >= 0) {
        url += `/${previousBadgeReducer.badgeList[lastIndex]?.docId}`;
      }
      const res = await axiosInstance.get(url);
      const { data, isEnd, isEmpty } = res.data;
      if (fetchMore) {
        dispatch(setBadgeList([...previousBadgeReducer.badgeList, ...data]));
      } else {
        dispatch(setBadgeList([...data]));
        dispatch(setBadgeIsEmpty(isEmpty));
      }
      dispatch(setBadgeIsEnd(isEnd));
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      dispatch(setBadgeLoading(false));
    }
  };
};

export const fetchMoreBadgesList = () => {
  return (dispatch, getState) => {
    const previousBadgeReducer = getState().badgeReducer;
    if (!previousBadgeReducer.isEnd) {
      dispatch(fetchBadgeList(true));
    }
  };
};
export const setDocProperty = (docId, name, val) => {
  return {
    type: SET_BADGE_DOC_PROPERTY,
    payload: {
      name,
      val,
      docId,
    },
  };
};
export const removeDocFromArray = (docId) => {
  return {
    type: REMOVE_BADGE_DOC_ARRAY,
    payload: {
      docId,
    },
  };
};
