import React, { useMemo } from "react";
import classes from "./opinion.module.scss";

import OpinonCard from "../../Components/OpinonCard";
import Button from "../../Utils/Button";
import Spinner from "../../Utils/Spinner";

const OpinionList = ({
  opinionList,
  isShowMoreBtn,
  isEnd,
  isEmpty,
  loading,
  moreBtnHandler,
  onEditClick,
  filterLoading,
}) => {
  // THIS IS RENDER
  const _renderList = () => {
    return opinionList.map((_o, i) => (
      <OpinonCard
        key={i}
        {..._o}
        varient="delete"
        onEditClick={() => onEditClick(_o)}
        style={{ overflow: "unset" }}
      />
    ));
  };

  return (
    <div className={classes["feeds-container"]}>
      <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
        {!filterLoading && _renderList()}
        {loading && (
          <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
        )}

        {isShowMoreBtn && !isEnd && !loading && (
          <div className={classes["more-btn"]}>
            <Button onClick={moreBtnHandler}>MORE</Button>
          </div>
        )}
        {/* {isEnd && !loading && (
          <p style={{ textAlign: "center" }}>No More Opinions</p>
        )} */}
        {isEmpty && !loading && <p style={{ textAlign: "center" }}>Empty</p>}
      </div>
    </div>
  );
};

export default OpinionList;
