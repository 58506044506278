import React, { useEffect, useState, Fragment } from "react";
import classes from "./editUserProfile.module.scss";
import axiosInstance from "./../../../axiosInstance";
import { toast } from "react-toastify";
import {
  UpdateUsername,
  IconWrapper,
  UploadAvatar,
  InstitutionSelector,
  ModalViewer,
} from "./../../../Components";
import {
  Button,
  Spinner,
  AddonInputField as InputField,
  AlertBox,
} from "../../../Utils";
import { ReactComponent as BackArrow } from "./../../../Assets/arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";

import {
  updateSingleUser,
  setDocProperty,
} from "./../../../Store/Actions/UserAction";

const profileTypes = ["image/png", "image/jpeg"];

// #EDIT PROFILE COMPONENT
const EditProfile = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(({ userReducer }) =>
    userReducer.userList.find((_o) => _o.docId === props.userEditId)
  );
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState({
    code: null,
    msg: "",
  });
  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    lawSchool: "",
    legalPosition: "",
  });
  const [
    showInstitutionSelectorModal,
    setShowInstitutionSelectorModal,
  ] = useState(false);

  useEffect(() => {
    const {
      firstName,
      lastName,
      lawSchool,
      legalPosition,
      institutionName,
      institutionUsername,
    } = userInfo;
    setFormFields({
      firstName: firstName || "",
      lastName: lastName || "",
      lawSchool: lawSchool || "",
      legalPosition: legalPosition || "",
      institutionName: institutionName || "",
      institutionUsername: institutionUsername || "",
    });
    setUsername(userInfo.userName);
  }, []);
  const profileUploadHandler = async (e) => {
    let selected = e.target.files[0];
    if (selected && profileTypes.includes(selected.type)) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("image", selected);
        formData.append("Content-Type", "application/json");
        let API_URL = `/user/uploadPhoto/${userInfo.docId}`;
        if (userInfo.imageFilename) {
          API_URL += `/${userInfo.imageFilename}`;
        }
        const res = await axiosInstance.post(API_URL, formData);
        const { data } = res.data;
        console.log(data);
        dispatch(
          setDocProperty(
            userInfo.docId,
            "profilePicture",
            `${data.imageUrl}&cache=${new Date().getTime()}`
          )
        );
        dispatch(
          setDocProperty(userInfo.docId, "imageFilename", data.imageFilename)
        );
        // props.closedEditUserModal();
        toast.success("Profile Picture Updated");
      } catch (err) {
        console.log(err.response);
      } finally {
        setLoading(false);
      }
    }
  };
  const onUsernameChange = (e) => {
    setUsername(e.target.value?.toLowerCase());
  };
  const onUpdateUsernameHandler = async () => {
    try {
      console.log("userInfo.docId--->", userInfo.docId, userInfo);
      const res = await axiosInstance.post("/user/updateUsername", {
        userName: username,
        userId: userInfo.docId,
      });
      // await getUserInfo();

      dispatch(updateSingleUser(userInfo.docId));
    } catch (err) {
      console.log(err.response);
    }
  };

  const onFormChangeHandler = (e) => {
    const { value, name } = e.target;
    setFormFields((p) => ({ ...p, [name]: value }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const reqData = { ...formFields };
      if (userInfo.lawSchool !== reqData.lawSchool) {
        reqData["approvalStatus"] = "pending";
      }
      console.log("userInfo.docId--->", userInfo.docId, userInfo);
      const res = await axiosInstance.put("/user/edit", {
        ...reqData,
        userId: userInfo.docId,
      });

      toast.success("Changes Saved Successfully");
      // await getUserInfo();
      dispatch(updateSingleUser(userInfo.docId));
    } catch (err) {
      console.log(err.response);
      setNotifyMsg({
        code: "red",
        msg: err?.response?.data?.error?.message || "There is some error",
      });
    } finally {
      setLoading(false);
    }
  };

  const selectorModalHandler = () => setShowInstitutionSelectorModal((p) => !p);

  const onSelectInstitution = ({
    institutionName,
    institutionUsername,
    id,
  }) => {
    setFormFields((p) => ({
      ...p,
      institutionName,
      institutionUsername,
      lawSchool: id,
    }));
    selectorModalHandler();
  };

  const insMsg = () => {
    if (userInfo?.approvalStatus === "pending") {
      return {
        type: "info",
        msg: `Your Institution Request is pending with ${userInfo?.institutionName}.`,
      };
    } else if (userInfo?.approvalStatus === "approved") {
      return {
        type: "success",
        msg: "Your Institution Request is Approved Congratulation!",
      };
    } else if (userInfo?.approvalStatus === "rejected") {
      return {
        type: "danger",
        msg: "Your Institution Request Rejected.",
      };
    } else {
      return {
        type: null,
        msg: "",
      };
    }
  };
  return (
    <Fragment>
      <InstitutionSelector
        isOpen={showInstitutionSelectorModal}
        onClosed={selectorModalHandler}
        onSelectHandler={onSelectInstitution}
      />
      {/* <ModalViewer
        header={HeaderComp}
        heading="Edit User Profile"
        onBack={props.closedEditUserModal}
      > */}
      <div className={classes.container}>
        {insMsg().type && (
          <AlertBox varient={insMsg().type} msg={insMsg().msg} />
        )}
        <h1 style={{ fontFamily: "Ubuntu" }}>Edit Profile</h1>
        <IconWrapper
          icon={BackArrow}
          className={classes.container__backArrow}
          onClick={props.closedEditUserModal}
        />

        <div className={classes["space-y-6"]}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <UploadAvatar
              src={`${userInfo.profilePicture}&cache=${new Date().getTime()}`}
              loading={loading}
              onImageInputChange={profileUploadHandler}
              defaultFallbackSrc={`https://firebasestorage.googleapis.com/v0/b/scout-speaks.appspot.com/o/default-profile-picture.jpg?alt=media&cache=${new Date().getTime()}`}
            />
          </div>
          <UpdateUsername
            uname={userInfo.userName}
            userName={username}
            onChange={onUsernameChange}
            updateHandler={onUpdateUsernameHandler}
          />

          <form className={classes["space-y-6"]} onSubmit={submitHandler}>
            <InputField
              name="firstName"
              type="text"
              placeholder="First Name"
              required
              value={formFields["firstName"]}
              onChange={onFormChangeHandler}
            />
            <InputField
              name="lastName"
              type="text"
              placeholder="Last Name"
              required
              value={formFields["lastName"]}
              onChange={onFormChangeHandler}
            />
            <InputField
              name="institutionName" //THIS IS THE INSTITUTION ID.
              type="text"
              placeholder="Law School Education"
              required
              onClick={selectorModalHandler}
              value={formFields["institutionName"] || ""}
              onChange={selectorModalHandler}
              // readOnly={true}
            />
            <br></br>
            <label className={classes.dropDown}>
              <span>Select Legal Position</span>
              <select
                name="legalPosition"
                value={formFields["legalPosition"]}
                onChange={onFormChangeHandler}
              >
                <option value="JD Candidate"> JD Candidate</option>
                <option value="LLM Candidate">LLM Candidate</option>
                <option value="Educational Faculty">Educational Faculty</option>
                <option value="Practicing Attorney">Practicing Attorney</option>
              </select>
            </label>

            {loading ? (
              <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
            ) : (
              <Button type="submit" className={classes["submitBtn"]}>
                Save Changes
              </Button>
            )}
            {notifyMsg.code && (
              <h3 style={{ color: notifyMsg.code || "inherit" }}>
                {notifyMsg.msg}
              </h3>
            )}
          </form>
        </div>
      </div>
      {/* </ModalViewer> */}
    </Fragment>
  );
};

export default EditProfile;
