import React, { Fragment, useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import classes from "./post.module.scss";
import UserRow from "../UserRow";
import IconWrapper from "../IconWrapper";

import { ReactComponent as BriefcaseIcon } from "./../../Assets/briefcase.svg";
import { ReactComponent as ShareIcon } from "./../../Assets/share-alt.svg";
import { ReactComponent as FlagAIcon } from "./../../Assets/flag-alt.svg";
import OpinonCard from "../OpinonCard";
import axiosInstance from "./../../axiosInstance";
import { AlertBox, Spinner } from "../../Utils";
import { toast } from "react-toastify";
import { useAuthContext } from "../../Context/AuthContext";
import { useHistory } from "react-router-dom";
import useCopyToClipboard from "../../Hooks/useCopyToClipboard";

import { PostViewer } from "./../index";
import HeaderLayout from "./../../Layout/Main/components/Header";
import { ReactComponent as SuccessIcon } from "./../../Assets/check-solid.svg";
import { ReactComponent as ClipboardIcon } from "./../../Assets/copy.svg";
const ADD_BRIEFCASE_URL = (id) => `/user/briefcase/${id}`;
const CHECK_POST_EXISTER_URL = (id) => `/user/postCounterExist/${id}`;
const REPORT_POST_URL = (id) => `/user/report-psot/${id}`;

const Post = ({
  isSinglePost = false,
  firstName,
  lastName,
  userName,
  legalPosition,
  profilePicture,
  opinionType,
  createdAt,
  plantiff,
  defendant,
  scoutsOpinion,
  dateOfPublish,
  docketNumber,
  briefcaseCount,
  shareCount,
  reportCount,
  userDetail,
  personalOpinion,
  docId,
  userUid,
  opinionId,
  termYear,
  actionContenChildren,
  varient,
  postWrapperStyle,
  // onFullReadHandler = () => {},
}) => {
  const history = useHistory();
  const { authState } = useAuthContext();
  const [postCounters, setPostCounters] = useState({
    briefcaseCount: 0,
    shareCount: 0,
    isPostReported: false,
    isAlreadyBriefcased: false,
    hidden: false,
  });
  const [postLoader, setLoader] = useState(false);

  const [postWrapperRef, isPostInView] = useInView({
    // threshold: 1,
    triggerOnce: true,
  });
  const [isCopied, handleCopy] = useCopyToClipboard(1200);
  const [fullReadPost, setFullReadPost] = useState(null);
  useEffect(() => {
    setPostCounters((p) => ({ ...p, briefcaseCount, shareCount }));
  }, []);
  useEffect(() => {
    if (isPostInView && authState.isAuthenticated && varient !== "ADMIN") {
      checkBriefCaseAlreadyOrNot();
    }
  }, [isPostInView]);

  const reportPostHandler = async () => {
    if (postLoader) {
      return;
    }
    if (!authState.isAuthenticated) {
      history.push("/s/login", { redirectUrl: `/post/${docId}` });
    } else {
      try {
        setLoader(true);
        const res = await axiosInstance.post(REPORT_POST_URL(docId));
        const { data } = res.data;
        console.log(data);
        setPostCounters((_p) => ({
          ..._p,
          isPostReported: data.reported,
        }));
        toast.info(data.reported ? "Post Reported" : "Post UnReported");
      } catch (err) {
      } finally {
        setLoader(false);
      }
    }
  };
  const addPostIntoBriefcaseHandler = async () => {
    if (postLoader) {
      return;
    }
    if (!authState.isAuthenticated) {
      history.push("/s/login", { redirectUrl: `/post/${docId}` });
    } else {
      try {
        setLoader(true);
        const res = await axiosInstance.post(ADD_BRIEFCASE_URL(docId));
        const { data } = res.data;
        console.log(data);
        const updatedBriefcaseCounter = data.briefcased ? 1 : -1;
        setPostCounters((_p) => ({
          ..._p,

          isAlreadyBriefcased: data.briefcased,
          briefcaseCount: _p.briefcaseCount + updatedBriefcaseCounter,
        }));
      } catch (err) {
      } finally {
        setLoader(false);
      }
    }
  };

  const checkBriefCaseAlreadyOrNot = async () => {
    if (!docId) {
      return;
    }
    try {
      const res = await axiosInstance.get(CHECK_POST_EXISTER_URL(docId));
      const { data } = res.data;
      setPostCounters((_p) => ({
        ..._p,
        isAlreadyBriefcased: data.briefcaseExist,
        isPostReported: data.isPostReported,
      }));
    } catch (err) {
      console.log(err.response);
    } finally {
    }
  };
  const onShareClickHandler = () => {
    if (!authState.isAuthenticated) {
      history.push("/s/login", { redirectUrl: `/post/${docId}` });
    } else {
      axiosInstance
        .post(`/user/createPost/${opinionId}`, {
          opinionType,
          personalOpinion,
          sharedFrom: userUid,
          sharedPostId: docId,
        })
        .then((doc) => {
          console.log(doc.data);
          toast.success("Shared");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.message);
          console.log(err.response);
        });
    }
  };

  const onCloseFullRead = () => setFullReadPost(null);
  const onFullReadHandler = () => setFullReadPost(true);

  let renderComp = (isViewer) => (
    <div
      className={classes.postWrapper}
      ref={postWrapperRef}
      style={postWrapperStyle}
    >
      {actionContenChildren}
      {postCounters.isPostReported && (
        <AlertBox
          varient="info"
          msg="You reported this post, site admin has been notified."
        />
      )}
      <UserRow
        src={profilePicture}
        createdAt={createdAt}
        profilePicture={profilePicture}
        legalPosition={legalPosition}
        firstName={firstName}
        lastName={lastName}
        userName={userName}
      />
      <OpinonCard
        plantiff={plantiff}
        defendant={defendant}
        scoutsOpinion={scoutsOpinion}
        dateOfPublish={dateOfPublish}
        docketNumber={docketNumber}
        varient="inside"
        termYear={termYear}
      />
      <div
        className={classes.feedOptionsWrapper}
        style={{ justifyContent: postLoader && "center" }}
      >
        {postLoader ? (
          <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
        ) : (
          <Fragment>
            <div style={{ marginLeft: "auto" }}>
              <button
                onClick={() =>
                  handleCopy(`https://scotusspeaksapp.web.app/post/${docId}`)
                }
                style={{
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                {isCopied ? (
                  <SuccessIcon color="green" width="19px" height="19px" />
                ) : (
                  <ClipboardIcon width="19px" height="19px" />
                )}
              </button>
            </div>
          </Fragment>
        )}
      </div>
      <div className={classes["dvider"]} />
      <div className={classes["position-info"]}>
        <h4>{`${legalPosition} ${firstName} ${lastName}`}</h4>
        <h4>{opinionType} SCOTUS decision</h4>
      </div>
      <div className={classes.opinonWrapper}>
        <p
          className={fullReadPost ? "" : " fade-ellipse-text "}
          style={{ wordWrap: "break-word" }}
        >
          {personalOpinion}
        </p>
        {!fullReadPost && <span onClick={onFullReadHandler}>Read more</span>}
      </div>
    </div>
  );
  return (
    <Fragment>
      <PostViewer
        show={fullReadPost}
        onBack={onCloseFullRead}
        header={HeaderLayout}
      >
        {renderComp(true)}
      </PostViewer>
      {renderComp(false)}
    </Fragment>
  );
};

export default Post;
