import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./speakerXInstitution.module.scss";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";
import ListComp from "./List";
import { fetchSpeakerAcList } from "./../../Store/Actions/PostsAction";
import { Button } from "../../Utils";

const PostsView = (props) => {
  const dispatch = useDispatch();
  const postReducer = useSelector(({ postReducer }) => postReducer);
  const { isEnd, list, isEmpty, loading } = postReducer;
  const [selectedFilter, setSelectedFilter] = useState("reports");
  useEffect(() => {
    dispatch(fetchSpeakerAcList(false));
  }, []);

  const moreBtnHandler = () => !isEnd && dispatch(fetchSpeakerAcList(true));
  // On Scroll reach to bottom fetch more
  // useScrollReachToBottom(moreBtnHandler);
  const onChangeFilter = (v) => {
    setSelectedFilter(v);
  };
  return (
    <div className={classes.profileWrapper}>
      <h1 className="text-center">POSTS</h1>
      <p className="text-center">Posts are sorted by the number of reports</p>
      <div className={classes.filterSpeakerWrapper}>
        <Button
          onClick={() => onChangeFilter("reports")}
          varient={selectedFilter === "reports" ? "none" : "outline"}
        >
          Posts
        </Button>
        <Button
          onClick={() => onChangeFilter("hidden")}
          varient={selectedFilter === "hidden" ? "none" : "outline"}
        >
          Hidden Posts
        </Button>
      </div>

      <ListComp
        list={list}
        isShowMoreBtn={!isEnd}
        isEnd={isEnd}
        loading={loading}
        moreBtnHandler={moreBtnHandler}
        isEmpty={isEmpty}
        selectedFilter={selectedFilter}
      />
    </div>
  );
};

export default PostsView;
