import { combineReducers } from "redux";

import settingReducer from "./settingReducer";
import authReducer from "./AuthReducer";
import badgeReducer from "./BadgeReducer";
import userReducer from "./UserReducer";
import institutionReducer from "./InstitutionReducer";
import speakerReducer from "./SpeakerReducer";
import opinionReducer from "./OpinionReducer";
import postReducer from "./PostReducer";

const combined = combineReducers({
  settingReducer,
  authReducer,
  badgeReducer,
  userReducer,
  institutionReducer,
  speakerReducer,
  opinionReducer,
  postReducer,
});
export default combined;
