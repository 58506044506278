import {
  INSTITUTION_IS_END,
  INSTITUTION_LOADING,
  INSTITUTION_IS_EMPTY,
  FETCH_INSTITUTION_LIST,
  SET_INSTITUTION_DOC_PROPERTY,
} from "./../Actions/InstitutionAction";

const initialState = {
  list: [],
  isEmpty: false,
  loading: false,
  isEnd: false,
};

const InstitutionReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case INSTITUTION_IS_END: {
      return {
        ...state,
        ...payload,
      };
    }
    case INSTITUTION_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case INSTITUTION_IS_EMPTY: {
      return {
        ...state,
        ...payload,
      };
    }
    case FETCH_INSTITUTION_LIST: {
      return {
        ...state,
        list: [...payload.list],
      };
    }
    case SET_INSTITUTION_DOC_PROPERTY: {
      return setDocProperty(state, payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const setDocProperty = (state, { val, name, docId }) => {
  const listClone = [...state.list];

  const findIndexOfDoc = listClone.findIndex((_o) => _o.docId === docId);

  if (findIndexOfDoc >= 0) {
    const docClone = { ...listClone[findIndexOfDoc] };
    docClone[name] = val;

    listClone[findIndexOfDoc] = docClone;

    return {
      ...state,
      list: [...listClone],
    };
  } else {
    return {
      ...state,
    };
  }
};
export default InstitutionReducer;
