import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./speakerXInstitution.module.scss";
import { Button, Spinner } from "../../Utils";
import SpeakerCard from "./Comps/SpeakerCard";
import Posts from "../../Components/Post";
import { DeleteShower } from "../../Components";
import { deletePost, hidePost } from "../../Store/Actions/PostsAction";

// # COMPONENT
const SpeakerList = ({
  list,
  isShowMoreBtn,
  isEnd,
  loading,
  moreBtnHandler,
  isEmpty,
  selectedFilter,
}) => {
  const dispatch = useDispatch();
  const postDeleteHandler = (postaData) => {
    dispatch(deletePost(postaData));
  };
  const postHideHandler = (postaData) => {
    dispatch(hidePost(postaData));
  };
  // THIS IS RENDER
  const _renderList = useMemo(() => {
    return list
      .filter((_o) => {
        if (selectedFilter === "reports") {
          return !_o.hidden;
        } else if (selectedFilter === "hidden") {
          return _o.hidden;
        }
      })
      .map((_o, i) => (
        <Posts
          key={i}
          {..._o}
          postWrapperStyle={{
            margin: "auto",
            border: _o.hidden ? "2px solid var(--red)" : "none",
          }}
          actionContenChildren={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  backgroundColor: "var(--red)",
                  padding: "6px 12px",
                  height: "34px",
                  borderRadius: "6px",
                  color: "white",
                  fontWeight: "600",
                  cursor: "pointer",
                  textTransform: "uppercase",
                }}
                onClick={() => postHideHandler(_o.docId)}
              >
                {_o.hidden ? "Un hide" : "Hide"}
              </span>
              <DeleteShower onClick={() => postDeleteHandler(_o.docId)} />
            </div>
          }
          varient="ADMIN"
        />
      ));
  }, [list, selectedFilter]);

  return (
    <div className={classes["feeds-container"]}>
      <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
        {_renderList}

        {loading && <Spinner />}

        {isShowMoreBtn && !isEnd && !loading && (
          <div className={classes["more-btn"]}>
            <Button onClick={moreBtnHandler}>MORE</Button>
          </div>
        )}
        {isEmpty && !loading && (
          <p style={{ textAlign: "center" }}>There is no Post</p>
        )}
        {_renderList.length === 0 && !loading && (
          <p style={{ textAlign: "center" }}>Empty</p>
        )}
      </div>
    </div>
  );
};

export default SpeakerList;
