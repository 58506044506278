import React from "react";
import { UpdateUsername } from "../../Components";
import { Controller } from "react-hook-form";
import { AddonInputField, Button, AddonSelectorField } from "./../../Utils";
import classes from "./useradmin.module.scss";
import DatePicker from "react-datepicker";

const FormModal = ({
  register,
  errors,
  submitHandler,
  handleSubmit,
  formLoader,
  modalCloseHandler,
  username,
  onUsernameChange,
  setIsValidUsername,
  formMsg,
  control,
}) => {
  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      className={classes.formWrapper}
    >
      <UpdateUsername
        userName={username}
        onChange={onUsernameChange}
        varient="CHECK_VALIDATION"
        setIsValidUsername={setIsValidUsername}
        requestUrl="/admin/checkInstitutionUserName"
      />
      <AddonInputField
        type="email"
        ref={register}
        name="email"
        required
        placeholder="Enter Email"
        error={!!errors.email}
        errorText={errors.email?.message}
      />
      <AddonInputField
        type="text"
        ref={register}
        name="institutionName"
        placeholder="Enter Institution Name"
        required
        error={!!errors.institutionName}
        errorText={errors.institutionName?.message}
      />
      <AddonInputField
        type="text"
        ref={register}
        name="location"
        placeholder="Enter Location"
        required
        error={!!errors.location}
        errorText={errors.location?.message}
      />
      <AddonInputField
        type="text"
        ref={register}
        name="state"
        placeholder="Enter State"
        required
        error={!!errors.state}
        errorText={errors.state?.message}
      />
      <AddonSelectorField
        ref={register}
        name="instType"
        placeholder="Public/Private"
        required
        error={!!errors.instType}
        errorText={errors.instType?.message}
        options={[
          { value: "public", text: "Public" },
          { value: "private", text: "Private" },
        ]}
      />

      <div className={classes.formDateField}>
        <p>Select Founded year</p>
        <Controller
          name="foundedYear"
          control={control}
          render={({ onChange, onBlur, value, name }) => (
            <DatePicker
              name="foundedYear"
              className={classes.formDateField__datePicker}
              ref={register}
              selected={value}
              onChange={onChange}
              showYearPicker
              dateFormat="yyyy"
              popperPlacement="top-start"
              // isClearable
              // placeholderText="Select Founded Year"
              // minDate={new Date("1/1/2013")}
              maxDate={new Date()}
            />
          )}
        />
      </div>

      {formMsg && (
        <h3 style={{ color: "red", margin: "6px 0px", textAlign: "center" }}>
          {formMsg}
        </h3>
      )}
      <div className={"flex space-bw " + classes.formActionButtons}>
        {!formLoader && (
          <Button type="submit" disabled={formLoader}>
            Create
          </Button>
        )}
        {!formLoader && (
          <Button
            type="button"
            onClick={modalCloseHandler}
            disabled={formLoader}
            varient="outline"
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

export default FormModal;
