import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import configureStore from "./Store/store";
import Routes from "./Routes";
import { ProvideUi } from "./Context/UiContext";
import ErrorCatcher from "./HOC/ErrorCatcher/ErrorCatcher";
import LoadingWrapper from "./Utils/LoadingWrapper/LoadingWrapper";
import { AuthProvider } from "./Context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App(props) {
  return (
    <ErrorCatcher>
      <Suspense fallback={<LoadingWrapper />}>
        <Router>
          <Provider store={configureStore()}>
            <AuthProvider>
              <ProvideUi>
                <ToastContainer
                  position="bottom-right"
                  autoClose={1500}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable={false}
                  pauseOnHover={false}
                />
                <Routes />
              </ProvideUi>
            </AuthProvider>
          </Provider>
        </Router>
      </Suspense>
    </ErrorCatcher>
  );
}

export default App;
